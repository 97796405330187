import React, { createContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import {
  doc,
  collection,
  query,
  getDocs,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase.js";
import { userService } from "../_services/user.service";
export const FirebaseContext = createContext();

const FirebaseContextProvider = ({ children }) => {
  const [contracts, setContracts] = useState([]);
  const [protocols, setProtocols] = useState([]);
  const user = userService.getId();

  const getContractLegal = async (contract) => {
    let productgroup = "";
    try {
      const productGroup = doc(db, "xc_product", contract.product_id);
      const querySnapshot = await getDoc(productGroup);

      if (querySnapshot.exists()) {
        return querySnapshot.data().product_group[0];
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSepaLegal = async (product_group) => {
    try {
      const links = {
        contract_legal: "",
        link_text1: "",
        link_text2: "",
        link_text3: "",
        link_url1: "",
        link_url2: "",
        link_url3: "",
        sepa_legal: "",
      };

      const sepa = doc(db, "xc_product_group", product_group);

      const querySnapshot = await getDoc(sepa);

      if (querySnapshot.exists()) {
        links.contract_legal = querySnapshot.data().contract_legal;
        links.link_text1 = querySnapshot.data().link_text1;
        links.link_text2 = querySnapshot.data().link_text2;
        links.link_text3 = querySnapshot.data().link_text3;
        links.link_url1 = querySnapshot.data().link_url1;
        links.link_url2 = querySnapshot.data().link_url2;
        links.link_url3 = querySnapshot.data().link_url3;
        links.sepa_legal = querySnapshot.data().sepa_legal;
      }

      return links;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const importContracts = query(
          collection(db, "xc_contract"),
          where("contractStatus", "==", "active"),
          where("created_by", "==", user)
        );
        const querySnapshot = await getDocs(importContracts);
        const list = [];

        const promises = querySnapshot.docs.map(async (doc) => {
          const contract = doc.data();
          let productGroup = null;
          let sepa = {
            contract_legal: "",
            link_text1: "",
            link_text2: "",
            link_text3: "",
            link_url1: "",
            link_url2: "",
            link_url3: "",
            sepa_legal: "",
          };

          if (contract.product_id) {
            productGroup = await getContractLegal(contract);
            sepa = await getSepaLegal(productGroup);
          }

          list.push({
            ...contract,
            ...sepa,
          });
        });

        await Promise.all(promises);

        setContracts(list);
      } catch (error) {
        console.log(error);
      }
    };

    fetchContracts();
  }, [user]);

  useEffect(() => {
    const fetchProtocols = async () => {
      let list = [];
      try {
        const importProtocols = query(
          collection(db, "xc_protocol"),
          where("protocol_status", "==", "active"),
          where("created_by", "==", user)
        );
        const querySnapshot = await getDocs(importProtocols);
        querySnapshot.forEach((doc) => {
          list.push(doc.data());
        });
        setProtocols(list);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProtocols();
  }, [user]);

  return (
    <FirebaseContext.Provider value={{ contracts, protocols }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseContextProvider };
