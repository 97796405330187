/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { FirebaseContextProvider } from "../../context/firebaseContext";
import { Route, Switch } from 'react-router-dom'
import {
	AsyncTarifCalculatorComponent,
} from 'routes';

const tarifCalculator = ({ match }) => (
	<Switch>
		<Store>
			<FirebaseContextProvider>
			     <Route path={`${match.url}`} component={AsyncTarifCalculatorComponent}></Route>
				</FirebaseContextProvider>
			
		</Store>

	</Switch>
)
export default tarifCalculator;