import React , { useState, useEffect }from "react";
import { Link } from "react-router-dom";

import ValidIcon from "../../assets/Icons/valid-icon.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import euro from "../../assets/Icons/euro.svg";
import NotValidIcon from "../../assets/Icons/notvalid_icon.svg";

import { Switch, Checkbox } from "@mui/material";
import ContractIcon from "../../assets/Icons/contract-icon.svg";
import PaymentIcon from "@mui/icons-material/Payment";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import Moment from "moment";

import _ from "lodash";

import { useLocation } from "react-router-dom";
import { kautionService } from "_services/kaution.service";
import { useHistory  } from "react-router-dom";

export default function ShowKaution() {
  const history  = useHistory();
  const location = useLocation();
  const [kaution, setKaution] = useState();
  function formatReason(reason) {
    if (reason == "notPresent") return "Nicht anwesend";
    else if (reason == "refuseToSign") return "Verweigert Unterschrift";
    else return "Keine Unterschrift";
  }
  function cancel() {
    history.push("/app/overview");

  }
  const getKautionToShow = async () => {
    await kautionService.getKaution(location.state.state.id_doc).then( result => {
      if (result){
        setKaution(result.data())
      }
    });
  };
  useEffect(() => {
    getKautionToShow();
  }, [location.state]);
  
  //const kaution = location.state;

  return (
    <>
    <div className="layoutstepsbar" style={{ height: "70px" }}>
            <div className="row layoutcontent full-height mr-0 ml-0">
                <div className="einzug width">
                  <img
                    className="RaumDetailsIcon"
                    src={euro}
                    alt="Übergabeprotokoll Icon"
                  />
                  <h3>Mieterkautions</h3>
                </div>
                <div className="Weiter align-end-button" >
                  <button
                    onClick={() => cancel()}
                    className="WeiterButton"
                    type="submit"
                  >
                    Zurück
                    <ArrowForwardIosIcon
                      className="icon-registrieren"
                      sx={{ fontSize: 16 }}
                    />
                  </button>
                </div>
              </div>
            </div>
      <div className="outer-container">
        <div className="card-style">
          <div className="SonCard">
            
            <div className="neuer">
              <img className="NeuerIcon" src={ValidIcon} alt="abschluss Icon" />
              <h3 style={{ textTransform: "none" }}> Kaution Berechnen </h3>
            </div>
            <div className="after-cards">
              <div className="formAbschlussOvwerview">
                <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview row w-full">
                  <div className="col-md-6 ">
                    <span style={{ color: "#888888" }}>Einzahlungsbetrag</span>
                    <div className="text-style">
                      <span>
                        {kaution?.kaution_calculator?.deposit_amount
                          ? new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(
                              kaution?.kaution_calculator?.deposit_amount
                            )
                          : "Kein Angabe"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <span style={{ color: "#888888" }}>Kautionsbetrag</span>
                    <div className=" text-style">
                      <span>
                        {kaution?.kaution_calculator?.amount
                          ? new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(kaution?.kaution_calculator?.amount)
                          : "Kein Angabe"}
                      </span>
                    </div>
                  </div>
                  <div
                    className="row col-md-12"
                    style={{ marginLeft: "-1rem" }}
                  >
                    <div className="col-md-6 align-items-left padding-room-tab flex-align-room ">
                      <span style={{ color: "#888888" }}>
                        Soll eine vorhandene Kaution in eine Bürgschaft
                        umgewandelt werden?
                      </span>
                    </div>
                    <div className="col-md-1 padding-room-tab  flex-align-room">
                      <Switch
                        name="deposit_converted"
                        checked={kaution?.kaution_calculator?.deposit_converted}
                        className="Switch-Kaution"
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="SonCard">
            <div className="neuer">
              <img className="NeuerIcon" src={ValidIcon} alt="abschluss Icon" />
              <h3 style={{ textTransform: "none" }}> Abschluss </h3>
            </div>
            <div className="after-cards">
              <div className="formAbschlussOvwerview">
                <div className="LigneWohnungsdatenOvwerview">
                  <span>Wohnungsdaten</span>
                </div>
                <div className="LigneSchlüssel Adresse-RenviernungsdatumOvwerview row w-full">
                  <div className="col-md-6">
                    <span style={{ color: "#888888" }}>Adresse</span>
                    <div className=" text-style">
                      <span>
                        {kaution?.object?.address?.street
                          ? kaution?.object?.address?.street
                          : "Straße fehlt"}
                        ,{" "}
                        {kaution?.object?.address?.house_number
                          ? kaution?.object?.address?.house_number
                          : "Hausnummer  fehlt"}
                      </span>
                      <br />
                      <span>
                        {kaution?.object?.address?.plz
                          ? kaution?.object?.address?.plz
                          : "PLZ fehlt"}
                        ,{" "}
                        {kaution?.object?.address?.city
                          ? kaution?.object?.address?.city
                          : "Ort fehlt"}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <span style={{ color: "#888888" }}>Renovierungsdatum</span>
                    <div className="text-style">
                      <span>
                        {kaution?.object?.date_of_renovation
                          ? Moment(
                              new Date(kaution?.object?.date_of_renovation)
                            ).format("DD.MM.YYYY")
                          : "Angabe fehlt"}
                      </span>
                    </div>
                    <span style={{ color: "#888888" }}>
                      Adresszusatz/Lage oder Wohnungsnummer
                    </span>

                    <div className=" text-style">
                      <span>
                        {kaution?.object?.address?.address_note
                          ? kaution?.object?.address?.address_note
                          : "Angabe fehlt"}
                        ,{" "}
                        {kaution?.object?.address?.position
                          ? kaution?.object?.address?.position
                          : "Angabe fehlt"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hrOvwerview" />
            </div>

            <div className="aftercardOvwerview">
              <div className="formAbschlussOvwerview">
                <div className="LigneWohnungsdatenOvwerview">
                  <span>Wohnungsgeber / Wohnungsnehmer</span>
                </div>
                <div className="LigneAdresse-RenviernungsdatumOvwerview">
                  <span style={{ fontWeight: "bold" }}>
                    {kaution?.landlord?.company_name
                      ? kaution?.landlord?.company_name
                      : "Unternehmensname fehlt"}
                  </span>
                  <span style={{ color: "#888888" }}>
                    Art des Wohnungsgebers
                  </span>
                </div>
                <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                  <div style={{ width: "50%" }}>
                    <span>
                      {kaution?.landlord?.salutation == null && "Anrede fehlt "}
                      {kaution?.landlord?.salutation == "noChoice" &&
                        "Keine Angabe "}
                      {kaution?.landlord?.salutation == "male" && "Herr "}
                      {kaution?.landlord?.salutation == "female" && "Frau "}
                      {kaution?.landlord?.title == "noChoice" &&
                        "Keine Angabe "}
                      {kaution?.landlord?.title == "dr" && "Dr. "}
                      {kaution?.landlord?.title == "prof" && "Prof. "}
                      {kaution?.landlord?.title == "ing" && "Ing. "}
                      {kaution?.landlord?.first_name == null &&
                        kaution?.landlord?.last_name == null && (
                          <span>Name und Vorname fehlt</span>
                        )}
                      {(kaution?.landlord?.first_name == null ||
                        kaution?.landlord?.first_name == "") &&
                        kaution?.landlord?.last_name != null && (
                          <>
                            {kaution?.landlord?.first_name
                              ? kaution?.landlord?.first_name
                              : " Vorname fehlt "}
                          </>
                        )}
                      {kaution?.landlord?.first_name != null &&
                        (kaution?.landlord?.last_name == null ||
                          kaution?.landlord?.last_name == "") && (
                          <>
                            {kaution?.landlord?.last_name
                              ? kaution?.landlord?.last_name
                              : " Nachname fehlt "}
                          </>
                        )}
                      {kaution?.landlord?.first_name != null &&
                        kaution?.landlord?.last_name != null && (
                          <>
                            {kaution?.landlord?.first_name}{" "}
                            {kaution?.landlord?.last_name}
                          </>
                        )}
                    </span>
                    <br />
                    <span>
                      {kaution?.landlord?.address?.street
                        ? kaution?.landlord?.address?.street
                        : "Straße fehlt"}
                      ,{" "}
                      {kaution?.landlord?.address?.house_number
                        ? kaution?.landlord?.address?.house_number
                        : "Hausnummer  fehlt"}
                    </span>
                    <br />
                    <span>
                      {kaution?.landlord?.address?.plz
                        ? kaution?.landlord?.address?.plz
                        : "PLZ fehlt"}
                      ,{" "}
                      {kaution?.landlord?.address?.city
                        ? kaution?.landlord?.address?.city
                        : "Ort fehlt"}
                    </span>
                  </div>
                  <div style={{ width: "50%" }}>
                    {(kaution?.landlord?.type == null ||
                      kaution?.landlord?.type == "") && (
                      <span>Angabe fehlt</span>
                    )}
                    {kaution?.landlord?.type == "noChoice" && (
                      <span style={{ color: "#000000" }}>Keine Angabe</span>
                    )}
                    {kaution?.landlord?.type == "company" && (
                      <span style={{ color: "#000000" }}>Gesellschaft</span>
                    )}
                    {kaution?.landlord?.type == "private" && (
                      <span style={{ color: "#000000" }}>Privat</span>
                    )}
                  </div>
                </div>
                <div className="LigneAdresse-RenviernungsdatumOvwerview">
                  <span style={{ color: "#888888" }}>E-mail:</span>
                  <span style={{ color: "#888888" }}>Telefonnummer:</span>
                </div>
                <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                  <div style={{ width: "50%" }}>
                    <span>
                      {kaution?.landlord?.email
                        ? kaution?.landlord?.email
                        : "Angabe fehlt"}
                    </span>
                  </div>
                  <div style={{ width: "50%" }}>
                    {kaution?.landlord?.phone
                      ? kaution?.landlord?.phone
                      : "Angabe fehlt"}{" "}
                  </div>
                </div>
              </div>
              <hr className="hrOvwerview" />
            </div>

            <div className="aftercardOvwerview">
              <div className="formAbschlussOvwerview">
                <div className="LigneWohnungsdatenOvwerview">
                  <span>Mieter</span>
                </div>

                <div className="LigneAdresse-RenviernungsdatumOvwerview">
                  <span style={{ fontWeight: "bold" }}>
                    {kaution?.renter?.salutation == null && "Anrede fehlt "}
                    {kaution?.renter?.salutation == "noChoice" &&
                      "Keine Angabe "}
                    {kaution?.renter?.salutation == "male" && "Herr "}
                    {kaution?.renter?.salutation == "female" && "Frau "}
                    {kaution?.renter?.title == "noChoice" && "Keine Angabe "}
                    {kaution?.renter?.title == "dr" && "Dr. "}
                    {kaution?.renter?.title == "prof" && "Prof. "}
                    {kaution?.renter?.title == "ing" && "Ing. "}

                    {
                      kaution?.renter?.first_name == null &&
                        kaution?.renter?.last_name == null &&
                        // <span>
                        "Name und Vorname fehlt"
                      // </span>
                    }
                    {(kaution?.renter?.first_name == null ||
                      kaution?.renter?.first_name == "") &&
                      kaution?.renter?.last_name != null && (
                        <>
                          {kaution?.renter?.first_name
                            ? kaution?.renter?.first_name
                            : " Vorname fehlt "}
                        </>
                      )}
                    {kaution?.renter?.first_name != null &&
                      (kaution?.renter?.last_name == null ||
                        kaution?.renter?.last_name == "") && (
                        <>
                          {kaution?.renter?.last_name
                            ? kaution?.renter?.last_name
                            : " Nachname fehlt "}
                        </>
                      )}
                    {kaution?.renter?.first_name != null &&
                      kaution?.renter?.last_name != null && (
                        <>
                          {kaution?.renter?.first_name}{" "}
                          {kaution?.renter?.last_name}
                        </>
                      )}
                  </span>
                  <span style={{ color: "#888888" }}>Geburtsdatum</span>
                </div>
                <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                  <div style={{ width: "50%" }}></div>
                  <div style={{ width: "50%" }}>
                    <div className="LigneAdresse-RenviernungsdatumOvwerview">
                      <span style={{ color: "#000000" }}>
                        {kaution?.renter?.date_of_birth
                          ? Moment(
                              new Date(kaution?.renter?.date_of_birth)
                            ).format("DD.MM.YYYY")
                          : "Angabe fehlt"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="LigneAdresse-RenviernungsdatumOvwerview">
                  <span style={{ color: "#888888" }}>E-mail:</span>
                  <span style={{ color: "#888888" }}>Telefonnummer:</span>
                </div>
                <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                  <div style={{ width: "50%" }}>
                    <span>
                      {kaution?.renter?.email
                        ? kaution?.renter?.email
                        : "Angabe fehlt"}
                    </span>
                  </div>
                  <div style={{ width: "50%" }}>
                    {kaution?.renter?.phone
                      ? kaution?.renter?.phone
                      : "Angabe fehlt"}{" "}
                  </div>
                </div>
              </div>
              <hr className="hrOvwerview" />
            </div>

            {kaution?.has_corenter && (
              <>
                <div className="aftercardOvwerview">
                  <div className="formAbschlussOvwerview">
                    <div className="LigneWohnungsdatenOvwerview">
                      <span>Weitere Mieter</span>
                    </div>
                    <div className="LigneAdresse-RenviernungsdatumOvwerview">
                      <span style={{ fontWeight: "bold" }}>
                        {kaution?.coRenter?.salutaion == null &&
                          "Anrede fehlt "}
                        {kaution?.coRenter?.salutaion == "noChoice" &&
                          "Keine Angabe "}
                        {kaution?.coRenter?.salutaion == "male" && "Herr "}
                        {kaution?.coRenter?.salutaion == "female" && "Frau "}
                        {kaution?.coRenter?.title == "noChoice" &&
                          "Keine Angabe "}
                        {kaution?.coRenter?.title == "dr" && "Dr. "}
                        {kaution?.coRenter?.title == "prof" && "Prof. "}
                        {kaution?.coRenter?.title == "ing" && "Ing. "}

                        {
                          kaution?.coRenter?.first_name == null &&
                            kaution?.coRenter?.last_name == null &&
                            // <span>
                            "Name und Vorname fehlt"
                          // </span>
                        }
                        {(kaution?.coRenter?.first_name == null ||
                          kaution?.coRenter?.first_name == "") &&
                          kaution?.coRenter?.last_name != null && (
                            <>
                              {kaution?.coRenter?.first_name
                                ? kaution?.coRenter?.first_name
                                : " Vorname fehlt "}
                            </>
                          )}
                        {kaution?.coRenter?.first_name != null &&
                          (kaution?.coRenter?.last_name == null ||
                            kaution?.coRenter?.last_name == "") && (
                            <>
                              {kaution?.coRenter?.last_name
                                ? kaution?.coRenter?.last_name
                                : " Nachname fehlt "}
                            </>
                          )}
                        {kaution?.coRenter?.first_name != null &&
                          kaution?.coRenter?.last_name != null && (
                            <>
                              {kaution?.coRenter?.first_name}{" "}
                              {kaution?.coRenter?.last_name}
                            </>
                          )}
                      </span>
                      <span style={{ color: "#888888" }}>Geburtsdatum</span>
                    </div>
                    <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                      <div style={{ width: "50%" }}></div>
                      <div style={{ width: "50%" }}>
                        <div className="LigneAdresse-RenviernungsdatumOvwerview">
                          <span style={{ color: "#000000" }}>
                            {kaution?.coRenter?.date_of_birth
                              ? Moment(
                                  new Date(kaution?.coRenter?.date_of_birth)
                                ).format("DD.MM.YYYY")
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="LigneAdresse-RenviernungsdatumOvwerview">
                      <span style={{ color: "#888888" }}>E-mail:</span>
                      <span style={{ color: "#888888" }}>Telefonnummer:</span>
                    </div>
                    <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                      <div style={{ width: "50%" }}>
                        <span>
                          {kaution?.coRenter?.email
                            ? kaution?.coRenter?.email
                            : "Angabe fehlt"}
                        </span>
                      </div>
                      <div style={{ width: "50%" }}>
                        {kaution?.coRenter?.phone
                          ? kaution?.coRenter?.phone
                          : "Angabe fehlt"}{" "}
                      </div>
                    </div>
                  </div>
                  <hr className="hrOvwerview" />
                </div>
              </>
            )}
          </div>

          <div className="SonCard">
            <div className="neuer">
              <PaymentIcon
                sx={{ fontSize: 50, marginLeft: "-0.9rem" }}
                className="NeuerIcon"
              />
              <h3>Bürgschaft</h3>
            </div>

            <div className="after-cards">
              <div className="textfieldsdateplzObOw position-relative">
                <div className="row align-items-center padding-product ">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="titleField">
                          <span style={{ color: "#888888" }}>Kalmiete</span>
                        </div>
                        <div className="resultField">
                          <span>
                            {kaution?.deposit_amount_information?.cold_rent
                              ? new Intl.NumberFormat("de-DE", {
                                  style: "currency",
                                  currency: "EUR",
                                }).format(
                                  kaution?.deposit_amount_information?.cold_rent
                                )
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="titleField">
                          <span style={{ color: "#888888" }}>Kautionshöhe</span>
                        </div>
                        <div className="resultField">
                          <span>
                            {kaution?.kaution_calculator?.deposit_amount
                              ? new Intl.NumberFormat("de-DE", {
                                  style: "currency",
                                  currency: "EUR",
                                }).format(
                                  kaution?.kaution_calculator?.deposit_amount
                                )
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 mb-3   ">
                      <div className="col-md-6">
                        <div className="titleField">
                          <span style={{ color: "#888888" }}>
                            Mietvertrag unterzeichnet am
                          </span>
                        </div>
                        <div className="resultField">
                          <span>
                            {kaution?.deposit_amount_information
                              ?.rental_agreement_signed_on
                              ? Moment(
                                  new Date(
                                    kaution?.deposit_amount_information?.rental_agreement_signed_on
                                  )
                                ).format("DD.MM.YYYY")
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="titleField">
                          <span style={{ color: "#888888" }}>
                            Mietbeggin am
                          </span>
                        </div>
                        <div
                          className="resultField"
                          style={{ color: "#000000" }}
                        >
                          {kaution?.deposit_amount_information?.rental_starts_on
                            ? Moment(
                                new Date(
                                  kaution?.deposit_amount_information?.rental_starts_on
                                )
                              ).format("DD.MM.YYYY")
                            : "Angabe fehlt"}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 mb-3">
                      <div className="row col-md-12">
                        <span style={{ marginLeft: "1rem", color: "#888888" }}>
                          WEITERE OPTIONEN:{" "} 
                        </span>
                      </div>
                      <div className="row col-md-12">
                        <div className="col-md-6 align-items-left padding-room-tab flex-align-room ">
                          <span>Kaution für neues Mietobjekt</span>
                        </div>
                        <div className="col-md-1 padding-room-tab  flex-align-room">
                          <Switch
                            name="deposit_for_newRrentalProperty"
                            checked={
                              kaution?.deposit_amount_information
                                ?.deposit_other_options
                                ?.deposit_for_newRrentalProperty
                            }
                            className="Switch-Kaution"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                      </div>
                      <div className="row col-md-12">
                        <div className="col-md-6 align-items-left padding-room-tab flex-align-room ">
                          <span>Kaution umwandeln</span>
                        </div>
                        <div className="col-md-1 padding-room-tab  flex-align-room">
                          <Switch
                            name="convert_security_deposit"
                            checked={
                              kaution?.deposit_amount_information
                                ?.deposit_other_options
                                ?.convert_security_deposit
                            }
                            className="Switch-Kaution"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                      </div>
                      <div className="row col-md-12">
                        <div className="col-md-6 align-items-left padding-room-tab flex-align-room ">
                          <span>Mietverhältnis befristet?</span>
                        </div>
                        <div className="col-md-1 padding-room-tab  flex-align-room">
                          <Switch
                            name="rental_agreement_limited"
                            checked={
                              kaution?.deposit_amount_information
                                ?.deposit_other_options
                                ?.rental_agreement_limited
                            }
                            className="Switch-Kaution"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          {kaution?.deposit_amount_information
                            ?.deposit_other_options
                            ?.rental_agreement_limited === true &&
                            kaution?.deposit_amount_information
                              ?.deposit_other_options
                              ?.rental_agreement_limited_date !== null &&
                            kaution?.deposit_amount_information
                              ?.deposit_other_options
                              ?.rental_agreement_limited_date !== "" && (
                              <span
                                style={{
                                  color: "#000000",
                                  marginRight: "-5.6rem",
                                  paddingLeft: "1rem",
                                }}
                              >
                                {Moment(
                                  new Date(
                                    kaution?.deposit_amount_information?.deposit_other_options?.rental_agreement_limited_date
                                  )
                                ).format("DD.MM.YYYY")}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="row col-md-12">
                        <div className="col-md-6 align-items-left padding-room-tab flex-align-room ">
                          <span>Ist das Mietverhältnis gekündigt?</span>
                        </div>
                        <div className="col-md-1 padding-room-tab  flex-align-room">
                          <Switch
                            name="rental_agreement_terminated"
                            checked={
                              kaution?.deposit_amount_information
                                ?.deposit_other_options
                                ?.rental_agreement_terminated
                            }
                            className="Switch-Kaution"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                      </div>
                      <div className="row col-md-12">
                        <div className="col-md-6 align-items-left padding-room-tab flex-align-room ">
                          <span>
                            Haben Sie Ihre Miete mehr als einmal unpünktlich
                            gezahlt?
                          </span>
                        </div>
                        <div className="col-md-1 padding-room-tab  flex-align-room">
                          <Switch
                            name="paid_rent_late_more_than_once"
                            checked={
                              kaution?.deposit_amount_information
                                ?.deposit_other_options
                                ?.paid_rent_late_more_than_once
                            }
                            className="Switch-Kaution"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                      </div>
                      <div className="row col-md-12">
                        <div className="col-md-6 align-items-left padding-room-tab flex-align-room ">
                          <span>Liegen aktuell Schäden am Mietobjekt vor?</span>
                        </div>
                        <div className="col-md-1 padding-room-tab  flex-align-room">
                          <Switch
                            name="currently_damage_to_the_rentalProperty"
                            checked={
                              kaution?.deposit_amount_information
                                ?.deposit_other_options
                                ?.currently_damage_to_the_rentalProperty
                            }
                            className="Switch-Kaution"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                      </div>
                      <div className="row col-md-12">
                        <div className="col-md-6 align-items-left padding-room-tab flex-align-room ">
                          <span>
                            Haben Sie die vereinbarte Mietkaution bereits
                            unvollständig erbracht?
                          </span>
                        </div>
                        <div className="col-md-1 padding-room-tab  flex-align-room">
                          <Switch
                            name="already_fully_paid_the_agreed_rentalDeposit"
                            checked={
                              kaution?.deposit_amount_information
                                ?.deposit_other_options
                                ?.already_fully_paid_the_agreed_rentalDeposit
                            }
                            className="Switch-Kaution"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                      </div>
                      <div className="row col-md-12">
                        <div className="col-md-6 align-items-left padding-room-tab flex-align-room ">
                          <span>
                            Mietkaution bereits teilweise/vollständig
                            verbraucht?
                          </span>
                        </div>
                        <div className="col-md-1 padding-room-tab  flex-align-room">
                          <Switch
                            name="rentalDeposit_already_usedUp"
                            checked={
                              kaution?.deposit_amount_information
                                ?.deposit_other_options
                                ?.rentalDeposit_already_usedUp
                            }
                            className="Switch-Kaution"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                      </div>
                      <div className="row col-md-12">
                        <div className="col-md-6 align-items-left padding-room-tab flex-align-room ">
                          <span>
                            Wohin soll die Bürgerschaft versendet werden?
                          </span>
                        </div>
                        <div className="col-md-3 padding-room-tab flex-align-room">
                          {kaution?.deposit_amount_information
                            ?.deposit_other_options?.guarantee_sent_to ==
                            null && (
                            <span
                              style={{
                                color: "#000000",
                                marginLeft: "-5.75rem",
                              }}
                            >
                              Kein Angabe
                            </span>
                          )}
                          {kaution?.deposit_amount_information
                            ?.deposit_other_options?.guarantee_sent_to ==
                            "noChoice" && (
                            <span
                              style={{
                                color: "#000000",
                                marginLeft: "-6.5rem",
                              }}
                            >
                              Bitte wählen
                            </span>
                          )}
                          {kaution?.deposit_amount_information
                            ?.deposit_other_options?.guarantee_sent_to ==
                            "to_old_address" && (
                            <span
                              style={{
                                color: "#000000",
                                marginLeft: "-3.25rem",
                              }}
                            >
                              an die alte Adresse
                            </span>
                          )}
                          {kaution?.deposit_amount_information
                            ?.deposit_other_options?.guarantee_sent_to ==
                            "to_new_address" && (
                            <span
                              style={{
                                color: "#000000",
                                marginLeft: "-2.75rem",
                              }}
                            >
                              an die neue Adresse
                            </span>
                          )}
                          {kaution?.deposit_amount_information
                            ?.deposit_other_options?.guarantee_sent_to ==
                            "to_landlord" && (
                            <span
                              style={{
                                color: "#000000",
                                marginLeft: "-4.25rem",
                              }}
                            >
                              an den Vermieter
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="SonCard">
            <div className="neuer">
              <PaymentIcon
                sx={{ fontSize: 50, marginLeft: "-0.9rem" }}
                className="NeuerIcon"
              />
              <h3>SEPA Lastschriftmandat</h3>
            </div>

            <div className="after-cards">
              <div className="textfieldsdateplzObOw position-relative">
                <div className="row align-items-center padding-product ">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="titleField">
                          <span style={{ color: "#888888" }}>Kontoinhaber</span>
                        </div>
                        <div className="resultField">
                          <span>
                            {kaution?.sepa_account_information
                              ?.sepa_account_holder
                              ? kaution?.sepa_account_information
                                  ?.sepa_account_holder
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="titleField">
                          <span style={{ color: "#888888" }}>Bank</span>
                        </div>
                        <div className="resultField">
                          <span>
                            {kaution?.sepa_account_information?.sepa_bank
                              ? kaution?.sepa_account_information?.sepa_bank
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 mb-3   ">
                      <div className="col-md-6">
                        <div className="titleField">
                          <span style={{ color: "#888888" }}>IBAN</span>
                        </div>
                        <div className="resultField">
                          <span>
                            {kaution?.sepa_account_information?.sepa_iban
                              ? kaution?.sepa_account_information?.sepa_iban
                              : "Angabe fehlt"}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="titleField">
                          <span style={{ color: "#888888" }}>BIC</span>
                        </div>
                        <div
                          className="resultField"
                          style={{ color: "#000000" }}
                        >
                          {kaution?.sepa_account_information?.sepa_bic
                            ? kaution?.sepa_account_information?.sepa_bic
                            : "Angabe fehlt"}
                        </div>
                      </div>
                    </div>
                    <div
                      className="row mt-3 mb-3 col-md-12"
                      style={{ textAlign: "justify", marginLeft: "-2.5rem" }}
                    >
                      <div
                        className="col-md-1"
                        style={{ marginTop: "-0.25rem" }}
                      >
                        <Checkbox
                          checked={
                            kaution?.sepa_account_information.sepa_check_box
                          }
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                          }}
                        />
                      </div>
                      <div
                        className="col-md-11"
                        style={{ marginLeft: "-0.25rem" }}
                      >
                        <p>
                          Ich ermächtige die R+V Versicherungen von Zahlungen
                          von meinem Konto mittels Lastschrift einzuziethen. *
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="SonCard">
            <div className="neuer">
              <PrivacyTipIcon
                style={{ width: 40, fontSize: "large", height: 40 }}
                className="NeuerIcon"
              />
              <h3 style={{ textTransform: "uppercase" }}>
              Allgemeine Geschäftsbedingungen
              </h3>
            </div>
            <div className="after-cards">
              <div className="textfieldsdateplzObOw position-relative">
                <div
                  className="row align-items-start padding-product col-md-11 "
                  style={{ textAlign: "justify", marginLeft: "-1.5rem" }}
                >
                  <div className="col-md-1" style={{ marginTop: "-0.25rem" }}>
                    <Checkbox
                      checked={kaution?.contract_leagl}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                      }}
                    />
                  </div>
                  <div className="col-md-11">
                    <p>
                      {" "}
                      Hiermit wird die plusForta GmbH als benutzer
                      bevollmächtigt. Die Tätigkeit der plusForta GmbH umfasst
                      unter anderem die Vermittlung der Bürgschaft für private
                      Mietkaution sowie die Prüfung, Bearbeitung und
                      Weiterleitung der eingereichten Unterlagen. *
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="SonCard mt-3 mb-3">
            <div
              className="neuer"
              style={{
                background:
                  "linear-gradient(95deg, rgb(98, 171, 18) 0%, rgb(98, 171, 18) 50%, rgb(151, 193, 88) 100%)",
              }}
            >
              <img
                className="NeuerIcon"
                src={ContractIcon}
                alt="Abschlussbemerkung Icon"
              />
              <h3 style={{ textTransform: "none" }}> Unterschriften </h3>
            </div>
            <div className="VerteilerContainer">
              <div className="formAbschlussOvwerview">
                <div className="sub-formverteilerOvwerview">
                  <div className="first-part-formverteilerOvwerview">
                    <div className="LigneWohnungsdatenOvwerview">
                      <span>Mieter</span>
                    </div>
                    <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                      <div style={{ width: "100%" }}>
                        <span style={{ fontSize: "700" }}>
                          {kaution?.renter?.salutation == null &&
                            "Anrede fehlt "}
                          {kaution?.renter?.salutation == "noChoice" &&
                            "Keine Angabe "}
                          {kaution?.renter?.salutation == "male" && "Herr "}
                          {kaution?.renter?.salutation == "female" && "Frau "}
                        </span>
                        <br />
                        <span style={{ fontSize: "700" }}>
                          {kaution?.renter?.first_name == null &&
                            kaution?.renter?.last_name == null && (
                              <span>Name und Vorname fehlt</span>
                            )}
                          {(kaution?.renter?.first_name == null ||
                            kaution?.renter?.first_name == "") &&
                            kaution?.renter?.last_name != null && (
                              <>
                                {kaution?.renter?.first_name
                                  ? kaution?.renter?.first_name
                                  : " Vorname fehlt "}
                              </>
                            )}
                          {kaution?.renter?.first_name != null &&
                            (kaution?.renter?.last_name == null ||
                              kaution?.renter?.last_name == "") && (
                              <>
                                {kaution?.renter?.last_name
                                  ? kaution?.renter?.last_name
                                  : " Nachname fehlt "}
                              </>
                            )}
                          {kaution?.renter?.first_name != null &&
                            kaution?.renter?.last_name != null && (
                              <>
                                {kaution?.renter?.first_name}{" "}
                                {kaution?.renter?.last_name}
                              </>
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {kaution?.renter?.signature_renter?.signature_renter ===
                  true ? (
                    <>
                      <div className="second-part-formverteilerOvwerview">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              kaution?.renter?.signature_renter
                                ?.signature_renter_image_remote_url
                            }
                            alt={
                              kaution?.renter?.signature_renter
                                ?.signature_renter_image_name
                            }
                            height="125px"
                            width="125px"
                          />
                          <span style={{ fontSize: "700", color: "#000000" }}>
                            {kaution?.renter?.signature_renter
                              ?.signature_renter_time_stamp !== null
                              ? Moment.unix(
                                  kaution?.renter?.signature_renter
                                    ?.signature_renter_time_stamp?.seconds
                                )
                                  .add(
                                    kaution?.renter?.signature_renter
                                      ?.signature_renter_time_stamp
                                      ?.nanoseconds / 1000000
                                  )
                                  .format("DD/MM/YYYY, HH:mm")
                              : "Kein Angabe"}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    // : (
                    //     kaution?.renter?.signature_renter
                    //         ?.signature_renter === false && (
                    //         <div className="second-part-formverteilerOvwerview">
                    //             {kaution?.renter?.signature_renter
                    //                 ?.signature_renter_refuse_reason ? (
                    //                 <span
                    //                     className="bad-status"
                    //                     style={{ marginLeft: "8rem" }}
                    //                 >
                    //                     <img
                    //                         className="notvalidateSclusselOvwerviewIcon"
                    //                         src={NotValidIcon}
                    //                         alt="notvalidateSclusselOvwerview Icon"
                    //                         style={{
                    //                             width: "1rem",
                    //                             marginRight: "0.25rem",
                    //                         }}
                    //                     />
                    //                     {formatReason(
                    //                         kaution?.renter?.signature_renter
                    //                             ?.signature_renter_refuse_reason
                    //                     )}
                    //                 </span>
                    //             )
                    <span
                      className="bad-status"
                      style={{ marginRight: "8rem" }}
                    >
                      <img
                        className="notvalidateSclusselOvwerviewIcon"
                        src={NotValidIcon}
                        alt="notvalidateSclusselOvwerview Icon"
                        style={{
                          width: "1rem",
                          marginRight: "0.25rem",
                        }}
                      />
                      Keine Unterschrift
                    </span>
                    //         )}
                    // </div>
                    // )
                  )}
                  <hr className="hrOvwerview" />
                </div>
                <div className="sub-formverteilerOvwerview">
                  <div className="first-part-formverteilerOvwerview">
                    <div className="LigneWohnungsdatenOvwerview">
                      <span>SEPA Mandat</span>
                    </div>
                    <div className="LigneDataAdresse-RenviernungsdatumOvwerview">
                      <div style={{ width: "100%" }}>
                        <span style={{ fontSize: "700" }}>
                          {kaution?.renter?.salutation == null &&
                            "Anrede fehlt "}
                          {kaution?.renter?.salutation == "noChoice" &&
                            "Keine Angabe "}
                          {kaution?.renter?.salutation == "male" && "Herr "}
                          {kaution?.renter?.salutation == "female" && "Frau "}
                        </span>
                        <br />
                        <span style={{ fontSize: "700" }}>
                          {kaution?.renter?.first_name == null &&
                            kaution?.renter?.last_name == null && (
                              <span>Name und Vorname fehlt</span>
                            )}
                          {(kaution?.renter?.first_name == null ||
                            kaution?.renter?.first_name == "") &&
                            kaution?.renter?.last_name != null && (
                              <>
                                {kaution?.renter?.first_name
                                  ? kaution?.renter?.first_name
                                  : " Vorname fehlt "}
                              </>
                            )}
                          {kaution?.renter?.first_name != null &&
                            (kaution?.renter?.last_name == null ||
                              kaution?.renter?.last_name == "") && (
                              <>
                                {kaution?.renter?.last_name
                                  ? kaution?.renter?.last_name
                                  : " Nachname fehlt "}
                              </>
                            )}
                          {kaution?.renter?.first_name != null &&
                            kaution?.renter?.last_name != null && (
                              <>
                                {kaution?.renter?.first_name}{" "}
                                {kaution?.renter?.last_name}
                              </>
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {kaution?.sepa_account_information?.sepa_check_box ===
                  true ? (
                    <>
                      <div className="second-part-formverteilerOvwerview">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              kaution?.sepa_account_information
                                ?.sepa_signature_url
                            }
                            alt={
                              kaution?.sepa_account_information
                                ?.sepa_signature_name
                            }
                            height="125px"
                            width="125px"
                          />
                          <span style={{ fontSize: "700", color: "#000000" }}>
                            {kaution?.sepa_account_information
                              ?.sepa_signature_time_stamp !== null
                              ? Moment.unix(
                                  kaution?.sepa_account_information
                                    ?.sepa_signature_time_stamp?.seconds
                                )
                                  .add(
                                    kaution?.sepa_account_information
                                      ?.sepa_signature_time_stamp?.nanoseconds /
                                      1000000
                                  )
                                  .format("DD/MM/YYYY, HH:mm")
                              : "Kein Angabe"}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <span
                      className="bad-status"
                      style={{ marginRight: "8rem" }}
                    >
                      <img
                        className="notvalidateSclusselOvwerviewIcon"
                        src={NotValidIcon}
                        alt="notvalidateSclusselOvwerview Icon"
                        style={{
                          width: "1rem",
                          marginRight: "0.25rem",
                        }}
                      />
                      Keine Unterschrift
                    </span>
                    //         )}
                    // </div>
                    // )
                  )}
                </div>
              </div>
              <hr className="hrOvwerview" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
