import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import React, {  useContext, useState, useEffect, createContext } from "react";
import { auth } from "../config/firebase";

const AuthContext = createContext(); 

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    localStorage.removeItem('currentuser');
    localStorage.removeItem("switchedcustomer");
    return signOut(auth);
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };
  const updateUserEmail = (email) => {
    return updateEmail(auth.currentUser, email);
  };
  const reauthenticate = (currentEmail, currentPassword) => {
    const credential = EmailAuthProvider.credential(
      currentEmail,
      currentPassword
    );
    return reauthenticateWithCredential(auth.currentUser, credential);
  };
  const changePassword = async (currentEmail, currentPassword, newPassword) => {
    await reauthenticate(currentEmail, currentPassword)
      .then(() => {
        var user = auth.currentUser;

        updatePassword(user, newPassword)
          .then(() => {
            return "Password updated!";
          })
          .catch((error) => {
            throw new Error("Error updating password");
          });
      })
      .catch((error) => {
        throw new Error("Error reauthenticating");
      });
  };

  const updateUserPassword = (password) => {
    return updatePassword(auth.currentUser, password);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <AuthContext.Provider
      value={{
        currentUser,
        login,
        signup,
        logout,
        resetPassword,
        updateUserEmail,
        updateUserPassword,
        changePassword,
        reauthenticate,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  const authContext = useContext(AuthContext);
  return authContext;
};

export default AuthProvider;
