import React, { useState, useEffect } from "react";
import { serverTimestamp } from "firebase/firestore";

import swal from "sweetalert";
import "date-fns";

import { TextField } from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { userService } from "../../_services/user.service";
import "date-fns";
import { promocodeService } from "../../_services/promo-code.service";
import { companyService } from "../../_services/company.service";
import { useHistory  } from "react-router-dom";

function AddPromoCode() {
  const history  = useHistory();
  const [errors, setErrors] = useState({});
  const [promocodeId, setPromocodeId] = useState();
  //Initialize the Notification state
  const [promoCode, setPromoCode] = useState("");
  const [company, setCompany] = useState({});
  // Get data from inputs
  function handleChange(e) {
    setPromoCode(e.target.value);
  }
  const getCompanyInfo = async () => {
    const companyId = await companyService.getCompanyID(
      "xc_user",
      userService.getId()
    );
    const companyInfo = await companyService.getCompany(companyId);
    setCompany(companyInfo);
  };
  function TranslationSubject(englishWord) {
    const translations = {
      "Promo code not found.": "Der Promo-Code konnte nicht gefunden werden",
      "Promo code has expired.":
        "Der Promo-Code ist abgelaufen",
      "Promo code has reached its usage limit":
        "Der Promo-Code nicht mehr gültig",
    };
    return translations[englishWord] || "Im Promo-Code ist ein Fehler aufgetreten";
  }
  //Validate required fields
  function validateFormData() {
    const errors = {};

    if (!promoCode) {
      errors.sending_method = true;
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  //add promocode
  const onSubmit = async (event) => {
    event.preventDefault();
    if (validateFormData()) {
      try {
        promocodeService.ValidePromoCode(promoCode).then(async (result) => {
          if (result.error) {
            swal({
              title: "HINWEIS",
              icon: "error",
              text: `${TranslationSubject(result.error)}`,
              button: "OK",
            }).then((willCreate) => {
              if (willCreate) {
                history.push("/app/overview");
              }
            });
          } else if (result.msg) {
            setPromocodeId(result.data.id)
            swal({
              title: " Herzlichen Glückwunsch!",
              icon: "info",
              text: "Als Premiumkunde haben Sie jetzt vollen Zugriff auf alle Vorteile und Funktionen. Viel Erfolg!",
              button: "OK",
            }).then(async (willCreate) => {
              if (willCreate) {
                await handleUpdateCompany();
                await handleUpdatePromoCode(result.data.id);
                history.push("/app/overview");
              }
            });
          }
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      swal({
        title: "HINWEIS",
        icon: "error",
        text: "Bitte füllen Sie alle Felder aus!",
        button: "OK",
      }).then((willVerify) => {
        if (willVerify) {
          history.push("#");
        }
      });
    }
  };
  const handleUpdatePromoCode = async (id) => {
    const userId = userService.getId();
    await promocodeService.updatePromoCode(userId, id);
  };
  const handleUpdateCompany = async () => {

    const updatedCompany = { ...company };
    updatedCompany.company_account_type = "premium";
    updatedCompany.company_profile_updatedAt = serverTimestamp();
    await companyService.updatedCompany(updatedCompany);
  };
  //Redirection to overview
  function cancel() {
    history.push("/app/overview");
  }
  useEffect(() => {
    setTimeout(() => {
      getCompanyInfo();
    }, 2000);
  }, []);
  return (
    <>
      <div className="layoutstepsbar" style={{ height: "70px" }}>
        <div class="row layoutcontent full-height mr-0 ml-0">
          <div className="einzug width ">
            <h3>X-CITE PROMOTION</h3>
          </div>
          <div className="Weiter align-end-button">
            <button
              onClick={() => cancel()}
              className="WeiterButton"
              type="submit"
            >
              Zurück
              <ArrowForwardIosIcon
                className="icon-registrieren"
                sx={{ fontSize: 16 }}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="outer-container">
        <div className="card-style">
          <div className="SonCard">
            <div className="card-shadow" style={{ backgroundColor: "#fff" }}>

              <div className="after-cards">
                <div className="textfieldsdateplzObOw">
                  <p>
                    Geben Sie den Promotionscode ein, den Sie von der X-SMART
                    erhalten haben
                  </p>
                  <div className="row mt-2 mb-2">
                    <div className="col-md-12">
                      <TextField
                        InputLabelProps={{
                          style: {
                            textTransform: "uppercase",
                            padding: "0 5px 0 0",
                            backgroundColor: "white",
                          },
                          //shrink: "true",
                        }}
                        name="promo_code"
                        id="promo_code"
                        value={promoCode}
                        type="text"
                        label="Ihr Promotionscode hier eintragen"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChange(e, "promo_code")}
                        required
                        error={errors.content}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="OvwerviewGlobalButton" style={{ marginLeft: "1.05rem", marginRight: "1.05rem", marginBottom: "1.05rem" }}>
                <button type="button" onClick={onSubmit}>
                  {/* Versenden */}
                  JETZT PREMIUMKUNDE WERDEN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alert}
    </>
  );
}

export default AddPromoCode;
