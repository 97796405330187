import {
  doc,
  getDocs,
  updateDoc,
  collection,
  query,
  where,
  setDoc,
  serverTimestamp,
  deleteDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../config/firebase";

import Moment from "moment";
import { userService } from "./user.service";
import { logBookService } from "./logBook.service";

export const kautionService = {
  duplicateKaution,
  deleteKaution,
  getinDraftList,
  getList,
  getKaution,
  updateKaution,
  createKaution,
  initKaution,
  deleteOrArchivKautions,
};

const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};

async function duplicateKaution(kaution) {
  const docRef = doc(db, "xc_kaution", kaution.id);
  try {
    await setDoc(docRef, kaution);
    return docRef.id;
  } catch (error) {
    console.log(error);
  }
}

async function deleteKaution(kaution_id) {
  const docRef = doc(db, "xc_kaution", kaution_id);
  try {
    await deleteDoc(docRef);
    return { success: true, message: "kaution deleted successfully." };
  } catch (error) {
    return { success: false, message: error.message };
  }
}

async function deleteOrArchivKautions(documents, type) {
  //const batch = db.batch();

  try {
    await documents.forEach((id) => {
      const docRef = doc(db, "xc_kaution", id);
      if (type == "delete") {
        updateDoc(docRef, {
          contract_status: "deleted",
          updated_at: Timestamp.now(),
        });
        var logbooks = [];
        setTimeout(async () => {
          logbooks = await logBookService.getByDoc(id, "kaution");
          if (logbooks != null && logbooks.length > 0) {
            logbooks.forEach(async (docs) => {
              await updateDoc(doc(db, "xc_logbook", docs.data().id), {
                status: "deleted",
              });
            });
          }
        }, 1000);
      } else {
        updateDoc(docRef, {
          contract_status: "inDraft",
          updated_at: Timestamp.now(),
        });
      }
    });
    //await batch.commit();
  } catch (error) {
    console.error("Error updating documents:", error);
  }
}

async function getList(field, id) {
  const queryRef = query(
    collection(db, "xc_kaution"),
    where("contract_status", "==", "active"),
    where(field, "==", id)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function getinDraftList(field, user_id) {
  const queryRef = query(
    collection(db, "xc_kaution"),
    where("contract_status", "==", "inDraft"),
    where(field, "==", user_id)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function getKaution(kaution_id) {
  const docRef = doc(db, "xc_kaution", kaution_id);
  return await getDoc(docRef);
}

async function updateKaution(kaution_id, kaution) {
  const docRef = doc(db, "xc_kaution", kaution_id);
  return await updateDoc(docRef, kaution);
}

async function createKaution(kaution) {
  const docRef = doc(db, "xc_kaution", kaution.id);
  await setDoc(docRef, kaution);
}

function initKaution(kaution, company_id, duplication = null) {
  const regex = /\+49\s/;

  if (kaution.object) {
    kaution.object.date_of_renovation = kaution.object.date_of_renovation
      ? Moment(kaution.object.date_of_renovation.toString()).format(
          "DD.MM.YYYY"
        )
      : "";
  }

  if (kaution.log_book_id && duplication) {
    kaution.log_book_id = null;
  }

  if (kaution.landlord) {
    kaution.landlord.phone = regex.test(kaution.landlord.phone)
      ? kaution.landlord.phone.replace(regex, "")
      : kaution.landlord.phone;
    kaution.landlord.mobile = regex.test(kaution.landlord.mobile)
      ? kaution.landlord.mobile.replace(regex, "")
      : kaution.landlord.mobile;
  }

  if (kaution.kaution_calculator.deposit_amount) {
    kaution.kaution_calculator.deposit_amount =
      kaution.kaution_calculator.deposit_amount.toString();
  }

  if (kaution.renter) {
    kaution.renter.date_of_birth = kaution.renter.date_of_birth
      ? Moment(kaution.renter.date_of_birth.toString()).format("DD.MM.YYYY")
      : "";

    kaution.renter.phone = regex.test(kaution.renter.phone)
      ? kaution.renter.phone.replace(regex, "")
      : kaution.renter.phone;
    kaution.renter.mobile = regex.test(kaution.renter.mobile)
      ? kaution.renter.mobile.replace(regex, "")
      : kaution.renter.mobile;

    if (kaution.renter.signature_renter) {
      kaution.renter.signature_renter = {
        signature_renter: false,
        signature_renter_image_name: null,
        signature_renter_image_remote_url: null,
        signature_renter_local_path_image: null,
        signature_renter_time_stamp: null,
        signature_renter_thumb: null,
      };
    }
  }

  if (kaution.coRenter) {
    kaution.coRenter.date_of_birth = kaution.coRenter.date_of_birth
      ? Moment(kaution.coRenter.date_of_birth.toString()).format("DD.MM.YYYY")
      : "";

    if (!kaution.coRenter.address) {
      kaution.coRenter = {
        first_name: kaution.coRenter.first_name,
        last_name: kaution.coRenter.last_name,
        salutaion: kaution.coRenter.salutaion,
        title: kaution.coRenter.title,
        date_of_birth: null,
        email: null,
        phone: null,
        mobile: null,
        address: {
          address_note: null,
          city: null,
          house_number: null,
          plz: null,
          street: null,
        },
      };
    }
  }

  if (kaution.deposit_amount_information) {
    kaution.deposit_amount_information.rental_agreement_signed_on = kaution
      .deposit_amount_information.rental_agreement_signed_on
      ? Moment(
          kaution.deposit_amount_information.rental_agreement_signed_on.toString()
        ).format("DD.MM.YYYY")
      : "";

    kaution.deposit_amount_information.rental_starts_on = kaution
      .deposit_amount_information.rental_starts_on
      ? Moment(
          kaution.deposit_amount_information.rental_starts_on.toString()
        ).format("DD.MM.YYYY")
      : "";

    if (kaution.deposit_amount_information.deposit_other_options) {
      kaution.deposit_amount_information.deposit_other_options.rental_agreement_limited_date =
        kaution.deposit_amount_information.deposit_other_options
          .rental_agreement_limited_date
          ? Moment(
              kaution.deposit_amount_information.deposit_other_options.rental_agreement_limited_date.toString()
            ).format("DD.MM.YYYY")
          : "";
    }
  }

  if (kaution.sepa_account_information) {
    if (kaution.sepa_account_information.sepa_signature) {
      delete kaution.sepa_account_information.sepa_signature;
    }
  }

  kaution = {
    ...kaution,
    company_id: company_id,
    created_by: kaution.created_by ? kaution.created_by : userService.getId(),
    created_at: kaution?.created_at
      ? new Date(kaution?.created_at)
      : serverTimestamp(),
    updated_at: serverTimestamp(),
  };
  return kaution;
}
