/**
 * Courses Routing File
 */

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from '../ProtectedRoutes.js'
import {
    AsyncProtocolPatternComponent,
} from 'routes';

const ProtokolPattern = ({ match }) => (
    <Switch>
        <Route path={`${match.url}`} component={AsyncProtocolPatternComponent} route={"protocol_pattern"} ></Route>

    </Switch>
)
export default ProtokolPattern;