/**
 * Courses Routing File
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from '../ProtectedRoutes.js'
import {
	AsyncContactComponent,
	AsyncSupportComponent,
	AsyncSwitchUserComponent
} from 'routes';

const Support = ({ match }) => (
	<Switch>
				<Route path={`${match.url}/switch_user`} component={AsyncSwitchUserComponent} route={"switch_user"} ></Route>
				<Route path={`${match.url}/contact`} component={AsyncContactComponent}></Route>
				<Route path={`${match.url}/ticket`} component={AsyncSupportComponent} route={"ticket"} ></Route>
				
			
	</Switch>
)
export default Support;