import React, { useEffect, useState } from 'react';
import ADDPromoCode from './addpromo-code';
import PromoCodeOverview from './promo-code-admin';
import { userService } from '_services';
import { useAuth } from 'context/AuthContext';
import { HulkPageLoader } from 'components/GlobalComponents';
import PromoCodePopup from './promocode-popup';

const Redirection = ({ match }) => {
  const { currentUser } = useAuth();
  const [user, setUser] = useState({});
  const [  show,setShow]=useState(true);


  useEffect(() => {
    const fetchUser = async () => {
      const userDoc = await userService.getUser(userService.getId());
      userDoc.forEach((doc) => {
        setUser(doc.data());
      });
    };

    fetchUser();
  }, [currentUser]);
  return (
    <>
      {user && user.role ? 
        user.role === 'admin' ? <PromoCodeOverview /> 
        : user.role != 'admin' && show  ?<PromoCodePopup show={show} setShow={setShow}/>:<HulkPageLoader />
        :<HulkPageLoader />}
    </>
  );
};

export default Redirection;
