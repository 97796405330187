import {
  doc,
  getDocs,
  updateDoc,
  collection,
  query,
  where,
  setDoc,
  Timestamp,
  serverTimestamp,
  deleteDoc,
  getDoc,
  limit,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import { db, auth } from "../config/firebase";
import Moment from "moment";
import { productService } from "./product.service";
import { userService } from "./user.service";
import { logBookService } from "./logBook.service";
export const protocolService = {
  updateProtocol,
  duplicateProtocol,
  createProtocol,
  initProtocolObject,
  initProtocolControl,
  updateProtocolControl,
  getList,
  getProtocolLimitedList,
  getinDraftProtocolLimitList,
  getProtocolListlength,
  getProtocolindraftListlength,
  getinDraftList,
  getProtocolListbystatusnotclosed,
  deleteProtocol,
  getProtocol,
  getProtocolControl,
  ContractHasToUpdate,
  update_consommation_in_existing_product,
  verify_product_existing,
  calculate_consommation,
  get_new_suggested_product,
  containsObjectWithType,
  downloadPdfProtocol,
  deleteOrArchivProtocols,
};

/*----------------------------------------------------------------*/

async function downloadPdfProtocol(protocol_id) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/protocol/download/pdf/${protocol_id}`,
    requestOptions
  ).then(handleResponse);
}
async function getProtocolListlength(user_id) {
  const queryRef = query(
    collection(db, "xc_protocol"),
    where("protocol_status", "==", "active"),
    where("created_by", "==", user_id)
  );
  const querySnapshot = await getDocs(queryRef);
  const documentCount = querySnapshot.size;
  return documentCount;
}

async function getProtocolindraftListlength(user_id) {
  const queryRef = query(
    collection(db, "xc_protocol"),
    where("protocol_status", "==", "inDraft"),
    where("created_by", "==", user_id)
  );
  const querySnapshot = await getDocs(queryRef);
  const documentCount = querySnapshot.size;
  return documentCount;
}

async function getProtocolListbystatusnotclosed(user_id) {
  const queryRef = query(
    collection(db, "xc_protocol"),
    where("protocol_status", "==", "active"),
    where("created_by", "==", user_id),
    where("closed", "==", false)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function getProtocolLimitedList(user_id, paginationsize) {
  let size = paginationsize ? paginationsize : 10;
  const queryRef = query(
    collection(db, "xc_protocol"),
    where("protocol_status", "==", "active"),
    where("created_by", "==", user_id),
    limit(size)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function getinDraftProtocolLimitList(user_id, paginationsize) {
  let size = paginationsize ? paginationsize : 10;
  const queryRef = query(
    collection(db, "xc_protocol"),
    where("protocol_status", "==", "inDraft"),
    where("created_by", "==", user_id),
    limit(size)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

/*----------------------------------------------------------------*/
//get protocol list by user
async function getList(field, id) {
  const queryRef = query(
    collection(db, "xc_protocol"),
    where("protocol_status", "==", "active"),
    where(field, "==", id)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function getinDraftList(field, user_id) {
  const queryRef = query(
    collection(db, "xc_protocol"),
    where("protocol_status", "==", "inDraft"),
    where(field, "==", user_id)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function updateProtocol(protocol_id, protocol) {
  const docRef = doc(db, "xc_protocol", protocol_id);
  return await updateDoc(docRef, protocol);
}

async function duplicateProtocol(protocol) {
  const docRef = doc(db, "xc_protocol", protocol.id);
  try {
    await setDoc(docRef, protocol);
    return docRef.id;
  } catch (error) {
    console.log(error);
  }
}

async function createProtocol(protocol) {
  const docRef = doc(db, "xc_protocol", protocol.id);
  await setDoc(docRef, protocol);
  const snapshot = await getDoc(docRef);
  const createdProtocol = snapshot.data();
}
async function deleteProtocol(protocol_id) {
  const docRef = doc(db, "xc_protocol", protocol_id);
  try {
    await deleteDoc(docRef);
    return { success: true, message: "Protocol deleted successfully." };
  } catch (error) {
    return { success: false, message: error.message };
  }
}

async function deleteOrArchivProtocols(documents, type) {
  try {
    await Promise.all(
      documents.map(async (id) => {
        const docRef = doc(db, "xc_protocol", id);
        let updateData = {
          updated_at: Timestamp.now(),
        };
        if (type === "delete") {
          updateData.protocol_status = "deleted";
        } else {
          updateData.protocol_status = "inDraft";
        }
        const docSnapshot = await getDoc(docRef);
        const docData = docSnapshot.data();
        let typeDoc = docSnapshot.data().is_control
          ? "protocol_control"
          : "protocol";
        // Check if pdf_created is true, then set status to deleted and add is_deleted field
        if (type === "delete" && docData.pdf_created === true) {
          updateData.protocol_status = "deleted";
          updateData.is_deleted = true;
        }

        await updateDoc(docRef, updateData);
        if (type == "delete") {
          var logbooks = [];
          setTimeout(async () => {
            logbooks = await logBookService.getByDoc(id, typeDoc);
            if (logbooks != null && logbooks.length > 0) {
              logbooks.forEach(async (docs) => {
                await updateDoc(doc(db, "xc_logbook", docs.data().id), {
                  status: "deleted",
                });
              });
            }
          }, 1000);
        }
      })
    );

    console.log("Documents updated successfully!");
  } catch (error) {
    console.error("Error updating documents:", error);
  }
}

function initProtocolObject(protocol, company_id, duplication = null) {
  const regex = /\+49\s/;

  if (protocol.log_book_id && duplication) {
    protocol.log_book_id = null;
  }
  if (protocol.protocol_counter) {
    protocol.protocol_counter.map((element) => {
      element.counter_created_at = new Date(element.counter_created_at);
      element.counter_updated_at = new Date(element.counter_updated_at);
    });
  }
  if (protocol.protocol_rooms) {
    protocol.protocol_rooms.map((element) => {
      if (element.room_image) {
        element.room_image.map((image) => {
          image.created_at = new Date(image.created_at);
          image.updated_at = new Date(image.updated_at);
        });
      }
      element.room_date_creation = new Date(element.room_date_creation);
      element.room_updated_at = new Date(element.room_updated_at);
      if (element.room_deffect) {
        element.room_deffect.map((def) => {
          def.created_at = new Date(def.created_at);
          def.updated_at = new Date(def.updated_at);
          def.room_deffect_date_of_fix = def.room_deffect_date_of_fix
            ? Moment(def.room_deffect_date_of_fix).format("DD.MM.YYYY")
            : "";
        });
      }
    });
  }

  if (protocol.protocol_keys) {
    protocol.protocol_keys.map((element) => {
      element.created_at = new Date(element.created_at);
      element.updated_at = new Date(element.updated_at);
      if (element.key_date_of_forwarding) {
        element.key_date_of_forwarding = element.key_date_of_forwarding
          ? Moment(
              typeof element.key_date_of_forwarding == "string"
                ? element.key_date_of_forwarding
                : new Date(element.key_date_of_forwarding)
            ).format("DD.MM.YYYY")
          : null;
      }
    });
  }

  if (protocol.roommates != null) {
    protocol.roommates.map((element) => {
      element.created_at = new Date(element.created_at);
      element.updated_at = new Date(element.updated_at);
    });
  }

  if (protocol.renter) {
    protocol.renter.renter_date_of_birth = protocol.renter.renter_date_of_birth
      ? Moment(protocol.renter.renter_date_of_birth).format("DD.MM.YYYY")
      : "";
    protocol.renter.renter_phone = regex.test(protocol.renter.renter_phone)
      ? protocol.renter.renter_phone.replace(regex, "")
      : protocol.renter.renter_phone;
    protocol.renter.renter_mobile = regex.test(protocol.renter.renter_mobile)
      ? protocol.renter.renter_mobile.replace(regex, "")
      :!protocol.renter.renter_mobile?null
      : protocol.renter.renter_mobile;
  }
  protocol.date_of_hand_over = protocol.date_of_hand_over
    ? Moment(
        typeof protocol.date_of_hand_over == "string"
          ? protocol.date_of_hand_over
          : new Date(protocol.date_of_hand_over)
      ).format("DD.MM.YYYY")
    : "";

  if (protocol.object)
    protocol.object.object_date_of_renovation = protocol.object
      .object_date_of_renovation
      ? Moment(
          typeof protocol.object.object_date_of_renovation == "string"
            ? protocol.object.object_date_of_renovation
            : new Date(protocol.object.object_date_of_renovation)
        ).format("DD.MM.YYYY")
      : "";

  if (protocol.owner) {
    protocol.owner.owner_phone = regex.test(protocol.owner.owner_phone)
      ? protocol.owner.owner_phone.replace(regex, "")
      : protocol.owner.owner_phone;
    protocol.owner.owner_mobile = regex.test(protocol.owner.owner_mobile)
      ? protocol.owner.owner_mobile.replace(regex, "")
      :!protocol.owner.owner_mobile?null
      : protocol.owner.owner_mobile;
  }
  if (protocol.landlord) {
    protocol.landlord.landlord_phone = regex.test(
      protocol.landlord.landlord_phone
    )
      ? protocol.landlord.landlord_phone.replace(regex, "")
      : protocol.landlord.landlord_phone;
    protocol.landlord.landlord_mobile = regex.test(
        protocol.landlord.landlord_mobile
    )
        ? protocol.landlord.landlord_mobile.replace(regex, "")
      :!protocol.landlord.landlord_mobile?null
      : protocol.landlord.landlord_mobile;
  }
  if (protocol.previous_renter) {
    protocol.previous_renter.previous_renter_phone_number = regex.test(
      protocol.previous_renter.previous_renter_phone_number
    )
      ? protocol.previous_renter.previous_renter_phone_number.replace(regex, "")
      : protocol.previous_renter.previous_renter_phone_number;
    protocol.previous_renter.previous_renter_mobile_number = regex.test(
        protocol.previous_renter.previous_renter_mobile_number
    )
        ? protocol.previous_renter.previous_renter_mobile_number.replace(regex, "")
        :!protocol.previous_renter.previous_renter_mobile_number?null
        : protocol.previous_renter.previous_renter_mobile_number;
  }
  if (protocol.renter)
    protocol.renter.signature_renter = {
      renter_refuse_signature: false,
      signature_renter: false,
      signature_renter_image_name: null,
      signature_renter_image_remote_url: null,
      signature_renter_local_path_image: null,
      signature_renter_refuse_reason: null,
      signature_renter_time_stamp: null,
    };
  if (protocol.landlord)
    protocol.landlord.signature_landlord = {
      signature_landlord: false,
      signature_landlord_image_name: null,
      signature_landlord_image_remote_url: null,
      signature_landlord_local_path_image: null,
      signature_landlord_time_stamp: null,
    };
  if (protocol.previous_renter)
    protocol.previous_renter.signature_previous_renter = {
      previous_renter_refuse_signature: false,
      signature_previous_renter: false,
      signature_previous_renter_image_name: null,
      signature_previous_renter_image_remote_url: null,
      signature_previous_renter_local_path_image: null,
      signature_previous_renter_refuse_reason: null,
      signature_previous_renter_time_stamp: null,
    };
  if (protocol.coRenter){
    protocol.coRenter.signature_coRenter = {
      image_name: null,
      local_path: null,
      refuse_reason: null,
      refuse_signature: false,
      remote_url: null,
      signature: false,
      thumb: null,
      time_stamp: null,
    };
    protocol.coRenter.date_of_birth = protocol.coRenter.date_of_birth
      ? Moment(protocol.coRenter.date_of_birth).format("DD.MM.YYYY")
      : "";
    protocol.coRenter.phone = regex.test(protocol.coRenter.phone)
      ? protocol.coRenter.phone.replace(regex, "")       
      :!protocol.coRenter.phone?null
      : protocol.coRenter.phone;
    protocol.coRenter.mobile = regex.test(
        protocol.coRenter.mobile
    )
        ? protocol.coRenter.mobile.replace(regex, "")
        :!protocol.coRenter.mobile?null
        : protocol.coRenter.mobile;
  }
  protocol = {
    ...protocol,
    agb_checked: false,
    company_id: company_id,
    closed: false,
    connection: protocol.connection ? protocol.connection : null,
    contract:
      protocol.contract && protocol.contract !== null
        ? protocol.contract
        : null,
    contract_is_expired: false,
    created_at: protocol?.created_at
      ? new Date(protocol?.created_at)
      : serverTimestamp(),
    created_by: userService.getId(),
    created_remote: true,
    duplicated: protocol.duplicated ? protocol.duplicated : false,
    ezmGas: protocol?.ezmGas,
    ezmStroom: protocol?.ezmStroom,
    gas_contract_created: false,
    suggested_product_electricity:
      protocol?.suggested_product_electricity != null
        ? protocol.suggested_product_electricity
        : null,
    suggested_product_gas:
      protocol?.suggested_product_gas != null
        ? protocol.suggested_product_gas
        : null,
    is_control: false,
    pdf_created: false,
    pdf_url: null,
    product_is_expired: false,
    protocol_status: "active",
    protocol_transfer_certificate: protocol.protocol_transfer_certificate
      ? protocol.protocol_transfer_certificate
      : false,
    real_estate_created: false,
    send_email: null,
    send_pdf: false,
    stroom_contract_created: false,
    synched_remote: true,
    is_from_web: true,
    updated_at: serverTimestamp(),
    witness_signature: {
      signature_witness: false,
      signature_witness_allowed: false,
      signature_witness_image_name: null,
      signature_witness_image_remote_url: null,
      signature_witness_local_path_image: null,
      signature_witness_name: null,
      signature_witness_refuse_reason: null,
      signature_witness_status: null,
      signature_witness_time_stamp: null,
      witness_refuse_signature: false,
    },
  };
  return protocol;
}

function initProtocolControl(protocol, company_id) {
  const regex = /\+49\s/;
  let newProtocolID = uuidv4();
  let created = new Timestamp(
    protocol?.created_at.seconds,
    protocol?.created_at.nanoseconds
  );

  if (protocol.log_book_id) {
    protocol.log_book_id = null;
  }

  if (protocol.protocol_rooms) {
    protocol.protocol_rooms.map((element) => {
      element.id = uuidv4();
      element.room_updated_at = new Timestamp(
        element?.room_updated_at.seconds,
        element?.room_updated_at.nanoseconds
      );
      element.room_date_creation = new Timestamp(
        element?.room_date_creation.seconds,
        element?.room_date_creation.nanoseconds
      );
      if (element.room_deffect) {
        element.room_deffect.map((def) => {
          def.created_at = new Timestamp(
            def.created_at.seconds,
            def.created_at.nanoseconds
          );
          def.updated_at = new Timestamp(
            def.updated_at.seconds,
            def.updated_at.nanoseconds
          );
        });
      }

      if (element.room_image) {
        element.room_image.map((image) => {
          image.updated_at = new Timestamp(
            image.updated_at.seconds,
            image.updated_at.nanoseconds
          );
          image.created_at = new Timestamp(
            image.created_at.seconds,
            image.created_at.nanoseconds
          );
        });
      }
    });
  }
  if (protocol.protocol_counter) {
    protocol.protocol_counter.map((element) => {
      element.id = uuidv4();
      element.counter_created_at = new Timestamp(
        element.counter_created_at.seconds,
        element.counter_created_at.nanoseconds
      );
      element.counter_updated_at = new Timestamp(
        element.counter_updated_at.seconds,
        element.counter_updated_at.nanoseconds
      );
    });
  }

  if (protocol.roommates != null) {
    protocol.roommates.map((element) => {
      element.id = uuidv4();
      element.created_at = new Timestamp(
        element.created_at.seconds,
        element.created_at.nanoseconds
      );
      element.updated_at = new Timestamp(
        element.updated_at.seconds,
        element.updated_at.nanoseconds
      );
    });
  }

  if (protocol.renter)
    protocol.renter.signature_renter = {
      renter_refuse_signature: false,
      signature_renter: false,
      signature_renter_image_name: null,
      signature_renter_image_remote_url: null,
      signature_renter_local_path_image: null,
      signature_renter_refuse_reason: null,
      signature_renter_time_stamp: null,
    };
  if (protocol.landlord)
    protocol.landlord.signature_landlord = {
      signature_landlord: false,
      signature_landlord_image_name: null,
      signature_landlord_image_remote_url: null,
      signature_landlord_local_path_image: null,
      signature_landlord_time_stamp: null,
    };
  if (protocol.previous_renter)
    protocol.previous_renter.signature_previous_renter = {
      previous_renter_refuse_signature: false,
      signature_previous_renter: false,
      signature_previous_renter_image_name: null,
      signature_previous_renter_image_remote_url: null,
      signature_previous_renter_local_path_image: null,
      signature_previous_renter_refuse_reason: null,
      signature_previous_renter_time_stamp: null,
    };
  if (protocol.coRenter)
    protocol.coRenter.signature_coRenter = {
      image_name: null,
      local_path: null,
      refuse_reason: null,
      refuse_signature: false,
      remote_url: null,
      signature: false,
      thumb: null,
      time_stamp: null,
    };

  if (protocol.protocol_keys) {
    protocol.protocol_keys.map((element) => {
      element.id = uuidv4();
      element.created_at = new Timestamp(
        element.created_at.seconds,
        element.created_at.nanoseconds
      );
      element.updated_at = new Timestamp(
        element.updated_at.seconds,
        element.updated_at.nanoseconds
      );
    });
  }

  if (protocol.owner) {
    protocol.owner.owner_phone = regex.test(protocol.owner.owner_phone)
      ? protocol.owner.owner_phone.replace(regex, "")
      : protocol.owner.owner_phone;
    protocol.owner.owner_mobile = regex.test(protocol.owner.owner_mobile)
      ? protocol.owner.owner_mobile.replace(regex, "")
      :!protocol.owner.owner_mobile?null
      : protocol.owner.owner_mobile;
  }
  if (protocol.landlord) {
    protocol.landlord.landlord_phone = regex.test(protocol.landlord.landlord_phone)
      ? protocol.landlord.landlord_phone.replace(regex, "")
      : protocol.landlord.landlord_phone;
    protocol.landlord.landlord_mobile = regex.test(
        protocol.landlord.landlord_mobile
      )
        ? protocol.landlord.landlord_mobile.replace(regex, "")
      :!protocol.landlord.landlord_mobile?null
      : protocol.landlord.landlord_mobile
  }
  if (protocol.previous_renter) {
    protocol.previous_renter.previous_renter_phone_number = regex.test(
      protocol.previous_renter.previous_renter_phone_number
    )
      ? protocol.previous_renter.previous_renter_phone_number.replace(regex, "")
      : protocol.previous_renter.previous_renter_phone_number;
    protocol.previous_renter.previous_renter_mobile_number = regex.test(
        protocol.previous_renter.previous_renter_mobile_number
      )
        ? protocol.previous_renter.previous_renter_mobile_number.replace(regex, "")
      :!protocol.previous_renter.previous_renter_mobile_number?null
      : protocol.previous_renter.previous_renter_mobile_number;
  }

  protocol = {
    ...protocol,
    id: newProtocolID,
    closed: false,
    company_id: company_id,
    closed: false,
    contract:
      protocol.contract && protocol.contract !== null
        ? protocol.contract
        : null,

    contract_is_expired: false,
    created_at: protocol?.created_at ? created : serverTimestamp(),
    created_by: userService.getId(),
    created_remote: true,
    duplicated: true,
    ezmGas: protocol?.ezmGas,
    ezmStroom: protocol?.ezmStroom,
    gas_contract_created: false,
    // genereal_notice: null,
    // internal_notice: null,
    is_control: true,
    main_protocol_id: protocol?.id,
    pdf_created: false,
    pdf_url: null,
    product_is_expired: false,
    protocol_status: "active",
    protocol_transfer_certificate: protocol.protocol_transfer_certificate
      ? protocol.protocol_transfer_certificate
      : false,
    real_estate_created: false,
    send_email: null,
    send_pdf: false,
    stroom_contract_created: false,
    synched_remote: true,
    is_from_web: true,
    updated_at: serverTimestamp(),
    witness_signature: {
      signature_witness: false,
      signature_witness_allowed: false,
      signature_witness_image_name: null,
      signature_witness_image_remote_url: null,
      signature_witness_local_path_image: null,
      signature_witness_name: null,
      signature_witness_refuse_reason: null,
      signature_witness_status: null,
      signature_witness_time_stamp: null,
      witness_refuse_signature: false,
    },
  };
  return protocol;
}

function updateProtocolControl(protocol, company_id) {
  const regex = /\+49\s/;
  let created = new Timestamp(
    protocol?.created_at.seconds,
    protocol?.created_at.nanoseconds
  );

  if (protocol.protocol_rooms) {
    protocol.protocol_rooms.map((element) => {
      element.room_updated_at = new Timestamp(
        element?.room_updated_at.seconds,
        element?.room_updated_at.nanoseconds
      );
      element.room_date_creation = new Timestamp(
        element?.room_date_creation.seconds,
        element?.room_date_creation.nanoseconds
      );
      if (element.room_deffect) {
        element.room_deffect.map((def) => {
          def.created_at = new Timestamp(
            def.created_at.seconds,
            def.created_at.nanoseconds
          );
          def.updated_at = new Timestamp(
            def.updated_at.seconds,
            def.updated_at.nanoseconds
          );
        });
      }

      if (element.room_image) {
        element.room_image.map((image) => {
          image.updated_at = new Timestamp(
            image.updated_at.seconds,
            image.updated_at.nanoseconds
          );
          image.created_at = new Timestamp(
            image.created_at.seconds,
            image.created_at.nanoseconds
          );
        });
      }
    });
  }
  if (protocol.protocol_counter) {
    protocol.protocol_counter.map((element) => {
      element.counter_created_at = new Timestamp(
        element.counter_created_at.seconds,
        element.counter_created_at.nanoseconds
      );
      element.counter_updated_at = new Timestamp(
        element.counter_updated_at.seconds,
        element.counter_updated_at.nanoseconds
      );
    });
  }

  if (protocol.roommates != null) {
    protocol.roommates.map((element) => {
      element.created_at = new Timestamp(
        element.created_at.seconds,
        element.created_at.nanoseconds
      );
      element.updated_at = new Timestamp(
        element.updated_at.seconds,
        element.updated_at.nanoseconds
      );
    });
  }

  if (protocol.landlord) {
    if (protocol.landlord.signature_landlord) {
      if (
        protocol.landlord.signature_landlord.signature_landlord_time_stamp !=
        null
      ) {
        protocol.landlord.signature_landlord.signature_landlord_time_stamp =
          new Timestamp(
            protocol.landlord.signature_landlord.signature_landlord_time_stamp.seconds,
            protocol.landlord.signature_landlord.signature_landlord_time_stamp.nanoseconds
          );
      }
    }
  }

  if (protocol.previous_renter) {
    if (protocol.previous_renter.signature_previous_renter) {
      if (
        protocol.previous_renter.signature_previous_renter
          .signature_previous_renter_time_stamp != null
      ) {
        protocol.previous_renter.signature_previous_renter.signature_previous_renter_time_stamp =
          new Timestamp(
            protocol.previous_renter.signature_previous_renter.signature_previous_renter_time_stamp.seconds,
            protocol.previous_renter.signature_previous_renter.signature_previous_renter_time_stamp.nanoseconds
          );
      }
    }
  }

  if (protocol.renter) {
    if (protocol.renter.signature_renter) {
      if (
        protocol.renter.signature_renter.signature_renter_time_stamp != null
      ) {
        protocol.renter.signature_renter.signature_renter_time_stamp =
          new Timestamp(
            protocol.renter.signature_renter.signature_renter_time_stamp.seconds,
            protocol.renter.signature_renter.signature_renter_time_stamp.nanoseconds
          );
      }
    }
  }

  if (protocol.coRenter) {
    if (protocol.coRenter.signature_coRenter) {
      if (protocol.coRenter.signature_coRenter.time_stamp != null) {
        protocol.coRenter.signature_coRenter.time_stamp = new Timestamp(
          protocol.coRenter.signature_coRenter.time_stamp.seconds,
          protocol.coRenter.signature_coRenter.time_stamp.nanoseconds
        );
      }
    }
  }

  if (protocol.witness_signature) {
    if (protocol.witness_signature.signature_witness_time_stamp != null) {
      protocol.witness_signature.signature_witness_time_stamp = new Timestamp(
        protocol.witness_signature.signature_witness_time_stamp.seconds,
        protocol.witness_signature.signature_witness_time_stamp.nanoseconds
      );
    }
  }

  if (protocol.protocol_keys) {
    protocol.protocol_keys.map((element) => {
      element.created_at = new Timestamp(
        element.created_at.seconds,
        element.created_at.nanoseconds
      );
      element.updated_at = new Timestamp(
        element.updated_at.seconds,
        element.updated_at.nanoseconds
      );
    });
  }

  if (protocol.owner) {
    protocol.owner.owner_phone = regex.test(protocol.owner.owner_phone)
      ? protocol.owner.owner_phone.replace(regex, "")
      : protocol.owner.owner_phone;
    protocol.owner.owner_mobile = regex.test(protocol.owner.owner_mobile)
      ? protocol.owner.owner_mobile.replace(regex, "")
      :!protocol.owner.owner_mobile?null
      : protocol.owner.owner_mobile;
  }
  if (protocol.landlord) {
    protocol.landlord.landlord_phone = regex.test(
      protocol.landlord.landlord_phone
    )
      ? protocol.landlord.landlord_phone.replace(regex, "")
      : protocol.landlord.landlord_phone;
    protocol.landlord.landlord_mobile = regex.test(
        protocol.landlord.landlord_mobile
      )
        ? protocol.landlord.landlord_mobile.replace(regex, "")
      :!protocol.landlord.landlord_mobile?null
      : protocol.landlord.landlord_mobile;
  }
  if (protocol.previous_renter) {
    protocol.previous_renter.previous_renter_phone_number = regex.test(
      protocol.previous_renter.previous_renter_phone_number
    )
      ? protocol.previous_renter.previous_renter_phone_number.replace(regex, "")
      : protocol.previous_renter.previous_renter_phone_number;
    protocol.previous_renter.previous_renter_mobile_number = regex.test(
        protocol.previous_renter.previous_renter_mobile_number
      )
        ? protocol.previous_renter.previous_renter_mobile_number.replace(regex, "")
      :!protocol.previous_renter.previous_renter_mobile_number?null
      : protocol.previous_renter.previous_renter_mobile_number;
  }

  protocol = {
    ...protocol,
    id: protocol.id,
    closed: false,
    company_id: company_id,
    closed: false,
    contract:
      protocol.contract && protocol.contract !== null
        ? protocol.contract
        : null,
    contract_is_expired: false,
    created_at: protocol?.created_at ? created : serverTimestamp(),
    created_by: userService.getId(),
    created_remote: true,
    duplicated: true,
    ezmGas: protocol?.ezmGas,
    ezmStroom: protocol?.ezmStroom,
    gas_contract_created: false,
    is_control: true,
    main_protocol_id: protocol.main_protocol_id,
    pdf_created: false,
    pdf_url: null,
    product_is_expired: false,
    protocol_status: "active",
    protocol_transfer_certificate: protocol.protocol_transfer_certificate
      ? protocol.protocol_transfer_certificate
      : false,
    real_estate_created: false,
    send_email: null,
    send_pdf: false,
    stroom_contract_created: false,
    synched_remote: true,
    is_from_web: true,
    updated_at: serverTimestamp(),
  };
  return protocol;
}

const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};

async function getProtocolControl(protocol_id) {
  const queryRef = query(
    collection(db, "xc_protocol"),
    where("main_protocol_id", "==", protocol_id),
    where("is_control", "==", true)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}
async function getProtocol(protocol_id) {
  const docRef = doc(db, "xc_protocol", protocol_id);
  return await getDoc(docRef);
}
function ContractHasToUpdate(Protocol) {
  let number = 1;
  if (Protocol && Protocol.roommates) {
    number += Protocol.roommates.length;
  }
  if (Protocol && Protocol.coRenter && Protocol.coRenter.first_name) {
    number += 1;
  }

  if (
    Protocol &&
    Protocol.contract &&
    Protocol.contract[0].persons_number !== number
  ) {
    return true;
  } else return false;
}

function containsObjectWithType(array, typeTocheck) {
  let typeExist = array.some((item) => item.contract_type === typeTocheck);

  return typeExist;
}
function update_consommation_in_existing_product(
  element,
  number,
  consommation,
  product
) {
  element.persons_number = number;
  element.consumption = consommation.toString();
  element.product_total_cost = productService
    .getTotalCostParMonth(product, consommation)
    .toString();
}

function verify_product_existing(
  products,
  element,
  number,
  consommation,
  strom_product_is_exist,
  gas_product_is_exist
) {
  if (products.length > 0) {
    let newProduct = products[0];
    element.persons_number = number;
    element.consumption = consommation.toString();
    element.product_total_cost = productService
      .getTotalCostParMonth(newProduct, consommation)
      .toString();
    element.product_id = newProduct.id;
    element.product_base_price = newProduct.basic_charge.toString();
    element.product_consumtion_price = newProduct.kwh_price
      .toFixed(2)
      .toString();
    element.product_name = newProduct.tariff_name;
    element.product_duration = newProduct.contract_duration;
    element.product_renewal = newProduct.contract_expension;
  } else {
    if (element.contract_type == "strom") {
      strom_product_is_exist = false;
    }
    if (element.contract_type == "gas") {
      gas_product_is_exist = false;
    }
  }
  return [strom_product_is_exist, gas_product_is_exist];
}
async function get_new_suggested_product(consommation, element) {
  let request = {
    consumption: consommation,
    contract_type: element.contract_type,
    postal_code: element.postal_code,
  };
  let products = await productService.SuggestTarifWithPriority(request, 1);
  return products;
}

function calculate_consommation(element, number) {
  let consommation = element.contract_type === "strom" ? 1400 : 2000;
  if (element.contract_type === "strom") {
    if (number == 2) {
      consommation = consommation + 800;
    }
    if (number - 2 > 0) {
      consommation = consommation + 1600 + (number - 3) * 500;
    }
  } else if (element.contract_type == "gas") {
    if (number - 1 > 0) {
      consommation = consommation + 2000 + (number - 2) * 1000;
    }
  }
  return consommation;
}

// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
