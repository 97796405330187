
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { Hidden, Drawer, Box } from '@material-ui/core';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components
import Sidebar from 'components/Sidebar';

// Actions
import { collapsedSidebarAction, darkModeAction, chooseThemeAction } from 'actions';

const drawerWidth = 300;

const styles = theme => ({
	root: {
		display: 'flex',
		height: '150vh'
	},
	content: {
		flexGrow: 1,
		marginLeft: -drawerWidth,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		[theme.breakpoints.down('xs')]: {
			paddingTop: 52,
		},
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	menuButton: {
		color: 'red',
		marginRight: theme.spacing(2),
		/* [theme.breakpoints.up('xs')]: {
			display: 'none',
		}, */
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	bgColor: {
		backgroundColor: 'rgb(0,96,137)',
		borderRight: '0',
		overflowY: 'hidden',
	},
	drawerPaper: {
		width: '100%',
		borderTopRightRadius: '8px',
		borderBottomRightRadius: '8px',
		background: 'linear-gradient(225.90383419648825deg, rgb(0, 159, 227) 0%, rgb(0, 96, 137) 100%)',
		borderRight: '0',
		boxShadow:"0 0 10px rgba(0, 0, 0, 0.21)",
		overflowY: 'hidden',
		[theme.breakpoints.up('md')]: {
			position: 'relative',
		},
	},
});

class HulkAppLayout extends Component {

	state = {
		mobileOpen: false,
		loading: true
	};

	renderPage() {
		const { children } = this.props;
		const { pathname } = this.props.location;
	
			return (
				<Scrollbars
					className="hulk-scroll main-content"
					autoHide
					autoHideDuration={160}
					ref="scrollUp"
					style={this.getScrollBarStyle()}
				>
					<div className="hulk-page-content">
						{children}
					</div>
				</Scrollbars>
			);
		
	}

	componentDidMount() {
		setTimeout(() => {
			if (this.state.loading === false && this.props.location.pathname === '/' ) {
				document.getElementsByClassName("hulk-page-content")[0].classList.add('fadeInUpShorter');
				setTimeout(() => {
					document.getElementsByClassName("hulk-page-content")[0].classList.remove('fadeInUpShorter');
				}, 1500)
			}
		}, 2500)
		const { selectedThemeColor } = this.props.settings;
		if ( this.props.location.search === '?darkmode=true&horizontalmenu=true' || this.props.location.search === '?horizontalmenu=true&darkmode=true'){
			this.onToggleHorizontalMenu(true);
		}
		
		if (selectedThemeColor) {
			this.chooseTheme(selectedThemeColor)
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			document.getElementsByClassName("hulk-page-content")[0].classList.add('fadeInUpShorter');
			window.scrollTo(0, 0);
			setTimeout(() => {
				document.getElementsByClassName("hulk-page-content")[0].classList.remove('fadeInUpShorter');
			}, 1500)
		}
		if(this.state.loading === true){
			setTimeout(() => {
				this.setState({ loading: false });
			}, 1500)
		}
	}
	
	//Scrollbar height
	getScrollBarStyle() {
		
			return {
				height: 'calc(150vh - 0px)',
			}
		
	}

	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};

	

	chooseTheme = (theme) => {
		document.body.classList.remove('light-theme', 'teal-theme', 'violet-theme')
		document.body.classList.add(theme);
		this.props.chooseThemeAction(theme);
	}

	render() {
		const { classes } = this.props;
		const { navCollapsed} = this.props.settings;
		return (
			<div>
				{ this.state.loading === true ? 
					<div id="loading-bg" className="hk-full-loader" >
						<div className="text-center">
							<Box mb={3}>
									<img alt="site-logo" width="110" height="30" src={require(`assets/Images/hulk-light.png`)} />
									
							</Box>
							<CircularProgress />
						</div>
					</div>
					:		
					<Fragment>
							<div className={`hk-app-layout bg-color-home1 ${classes.root}`}>
								
							
								<nav aria-label="menu-sidebar">
									{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
									<Hidden smUp implementation="css">
										<Drawer
											variant="temporary"
											anchor='left'
											open={this.state.mobileOpen}
											onClose={this.handleDrawerToggle}
											classes={{
												paper: `${classes.bgColor} ${classes.drawer}`,
											}}
											ModalProps={{
												keepMounted: true, // Better open performance on mobile.
											}}
										>
											<div>
												<Sidebar closeSidebar={this.handleDrawerToggle} />
											</div>
										</Drawer>
									</Hidden>
									<Hidden smDown implementation="css"
										className={clsx(classes.drawer, {
											[`rtl-sidebar`]: !navCollapsed,
										})}
									>
										<Drawer
											variant="persistent"
											anchor="left"
											open={navCollapsed}
											classes={{
												paper: ` ${classes.drawerPaper}`,
											}}
										>
											<Sidebar />
										</Drawer>
									</Hidden>
								</nav>
								<main
									className={clsx(classes.content, {
										[classes.contentShift]: navCollapsed,
									}, 'hk-main')}
								>
									<div className="hk-page">
										{this.renderPage()}
									</div>
								</main>
							</div>
							
					</Fragment>
				}
			</div>
		);
	}		
}

// map state to props
const mapStateToProps = ({ settings }) => {
	return { settings }
}

export default withRouter(connect(mapStateToProps, {
	collapsedSidebarAction,
	darkModeAction,
	chooseThemeAction
})(withStyles(styles)(HulkAppLayout)));