/**
 * App Config File
*/

const AppConfig = {
	brandName: 'XCITE',
	copyRightText: 'Copyright © 2021 IRON Network, All Rights Reserved.',
	navCollapsed: true,
	mobileSidebarToggle: false,
	isDarkModeActive: false,
	isRtlActive: false,
	isHorizontalMenuActive: false,
	isMiniSidebarActive:false,
   selectedThemeColor:'light-theme',
   locale: {
      languageId: 'german',
      locale: 'de',
      name: 'Germany',
      icon: 'de.png',
	},
	// Default Theme Colors 
	lightThemeColors: {
		'primary': '#4d7df2',
		'secondary': '#ff8600',
		'success': '#00d41a',
		'info': '#00d0bd',
		'warning': '#ffb70f',
		'error': '#e53935',
		'textPrimary': '#29303b',
		'textSecondary': '#717275',
		'divider': '#e0e0e0',
		'icon': '#717275',
		'bgPaper': '#FFFFFF',
		'bgDefault': '#f3f7fa',
		'white':'#ffffff',
		'black':'#000000'
	},

	
}

export default AppConfig;