import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDsJkpsAR5XqEYyAtPKaJZc_vCHSDdhzDE",
  authDomain: "xc-flutter.firebaseapp.com",
  databaseURL:
    "https://xc-flutter-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "xc-flutter",
  storageBucket: "xc-flutter.appspot.com",
  messagingSenderId: "385448258457",
  appId: "1:385448258457:web:9400afc6077bf3b3bd4108",
  measurementId: "G-5RW0557PDH",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);

export default auth;
