/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from '../ProtectedRoutes.js'
import {
	AsyncCompanyComponent,
} from 'routes';

const Company = ({ match }) => (
	<Switch>
		<Store>
			 <Route path={`${match.url}/:companyID`} component={AsyncCompanyComponent} route={"Unternehmen"} ></Route>
			
		</Store>

	</Switch>
)
export default Company;