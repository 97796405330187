import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ticketService } from "../../../_services/ticket.service";
import moment from "moment";
import { userService } from "../../../_services/user.service";
import swal from "sweetalert";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";

function TicketReply() {
  const token = new URLSearchParams(window.location.search).get("token");
  const decoded = jwt_decode(token);
  const id = decoded.user.id;
  const [data, setData] = useState({});
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [logList, setLogList] = useState([]);
  // const [limitMessage, setLimitMessage] = useState(10);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    company: "",
    adress: "",
    phone: "",
    gender: "",
  });
  //handleChangeField
  const handleChangeField = (e) => {
    setMessage(e.target.value);
  };
  const history = useHistory();
  //Redirection to overview
  function cancel() {
    history.push("/app/adminstration/tickets");
  }
  // get blog details by name
  const getTicketByID = async () => {
    await ticketService
      .getTicketById(id)
      .then((results) => {
        if (results.msg) {
          setData(results.data);
          setLogList((prevLogList) => {
            const newLogList = [...prevLogList];

            // Add objects based on conditions only if they don't already exist in the list
            if (
              results.data.ticket_closed != null &&
              !isAlreadyAdded(newLogList, results.data.ticket_closed)
            ) {
              newLogList.push({
                date: results.data.ticket_closed,
                type: "status-closed",
              });
            }
            if (
              results.data.ticket_in_progress != null &&
              !isAlreadyAdded(newLogList, results.data.ticket_in_progress)
            ) {
              newLogList.push({
                date: results.data.ticket_in_progress,
                type: "status-progress",
              });
            }
            if (
              results.data.created_at != null &&
              !isAlreadyAdded(newLogList, results.data.created_at)
            ) {
              newLogList.push({
                date: results.data.created_at,
                type: "status-open",
              });
            }

            // Add user messages
            results.data.user_messages.forEach((item) => {
              if (!isAlreadyAdded(newLogList, item.updated_at)) {
                newLogList.push({
                  date: item.updated_at,
                  type: `message-${item.type}`,
                });
              }
            });

            return newLogList;
          });
        }
      })
      .catch((err) => {
        console.error("error Get Ticket", err);
      });
  };
  const UpdateStatus = (status) => {
    const object = {
      status: status,
    };
    ticketService
      .updateStatus(id, JSON.stringify(object))
      .then((results) => {
        if (results.msg) {
          // setData(results.data);
        }
      })
      .catch((err) => {
        console.error("error Get Ticket", err);
      });
  };
  const getUserName = async () => {
    if (data.user_id != undefined) {
      const userDoc = await userService.getUser(data?.user_id);
      userDoc.forEach(async (doc) => {
        setUserData({
          ...userData,
          first_name: doc.data().first_name,
          last_name: doc.data().last_name,
          phone: doc.data().phone_number,
          adress: `${doc.data().address.street && doc.data().address.street}${doc.data().address.house_number &&
            " , " + doc.data().address.house_number
            }${doc.data().address.plz && " , " + doc.data().address.plz}${doc.data().address.city && " - " + doc.data().address.city + " "
            }${doc.data().address.address_note && doc.data().address.address_note
            }`,
          company: doc.data().company_name,
          gender: doc.data().gender,
        });
      });
    }
  };
  // Define a function to convert a date object to milliseconds
  function compareNumbers(a, b) {
    return (
      a.date.seconds +
      a.date.nanoseconds -
      (b.date.seconds + a.date.nanoseconds)
    );
  }

  function sortList(logList) {
    return logList.slice().sort(compareNumbers);
  }
  const handleClose = () => {
    if (data.status !== "completed") {
      UpdateStatus("completed");
      window.location.reload();
    }
  };
  const handleClick = () => {
    if (!message) {
      setError("please write your message");
      swal("Bitte schreiben Sie Ihre Nachricht.").then((result) => {
        if (result) {
          setError();
        }
      });
      return;
    } else {
      let newobject = {
        message: message,
        ticket_id: id,
        type: "client",
      };
      ticketService.sendMessage(JSON.stringify(newobject)).then((result) => {
        if (result.msg == "success") {
          swal({
            title: "Ihr Nachricht wurde versendet.",
            dangerMode: false,
          }).then((result) => {
            if (result) {
              history.push("/");
            }
          });
        } else {
          swal({
            title: "Fehler beim Senden der Nachricht",
            dangerMode: true,
          });
        }
      });
    }
  };
  useEffect(() => {
    if (id != undefined) {
      getTicketByID(id);
    }
  }, [id]);
  useEffect(() => {
    if (data.user_id != undefined) {
      getUserName();
    }
    if (data && data.status == "completed") {
      UpdateStatus("open");
    }
  }, [data?.user_id]);

  function Translation(englishWord) {
    const translations = {
      "Report synchronization issues": "Ein Problem bei der Synchronisierung",
      "Report issues regarding a kaution document":
        "Ein Problem zu einer Kautionsbürgschaft",
      "Report issues regarding a contrat document":
        "Ein Problem zu einem Gas oder Stromauftrag",
      "Report issues regarding a protocol document":
        "Ein Problem zu einem Protokoll",
      "General Question": "Eine Allgemeine Frage",
      "Report a different problem": "Ein anderes Problem",
      "admin ticket": "Admin-Ticket",
      "Error occurs in the app": "APP",
      "Error occurs on the web": "Web",
      "Error occurs on the web and in the app": "Beide ",
    };
    return translations[englishWord] || englishWord;
  }

  const getStatusComponent = (status) => {
    switch (status) {
      case "open":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#de0404",
              textTransform: "uppercase",
            }}
          >
            <CircleIcon style={{ marginRight: "5px", color: "#de0404" }} />
            NEU
          </div>
        );
      case "progress":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#F4BF55",
              textTransform: "uppercase",
            }}
          >
            <PendingOutlinedIcon
              style={{ marginRight: "5px", color: "#F4BF55" }}
            />
            In Bearbeitung
          </div>
        );
      case "completed":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#62AB12",
              textTransform: "uppercase",
            }}
          >
            <DoneIcon style={{ marginRight: "5px", color: "#62AB12" }} />
            Geschlossen
          </div>
        );
      default:
        return null;
    }
  };
  const [expandedItemId, setExpandedItemId] = useState(null);

  const handleToggleMessage = (itemId) => {
    setExpandedItemId(itemId === expandedItemId ? null : itemId);
  };

  const renderSupportMessages = (date) => {
    return findItemsByUpdatedAt(data?.user_messages, date)?.map(
      (item, index) => {
        const isExpanded =
          expandedItemId ===
          index + findIndexOfItemByUpdatedAt(data?.user_messages, date);
        const contentmessage = containsHTMLTags(item?.message).ishtml
          ? containsHTMLTags(item?.message).content
          : item?.message;
        const message = isExpanded
          ? contentmessage
          : contentmessage.substring(0, 10);

        return (
          <div
            className="log-tickets mb-1 mt-1 d-flex align-items-center"
            style={{ width: "100%", border: "1px solid #009fe3" }}
            key={index + findIndexOfItemByUpdatedAt(data?.user_messages, date)}
          >
            <div>
              {moment
                .unix(item?.updated_at?.seconds)
                .add(item?.updated_at?.nanoseconds / 1000000)
                .format("DD.MM.YYYY - HH:mm:ss")}
            </div>
            <div
              className="row"
              style={{
                width: "60%",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="col-md-11">
                {containsHTMLTags(item?.message).ishtml ? (
                  <p>
                    {message}
                    {!isExpanded ? "..." : ""}
                  </p>
                ) : (
                  <p>
                    {message}
                    {!isExpanded ? "..." : ""}
                  </p>
                )}
              </div>
              <div className="col-md-1">
                <ArrowBackIosIcon
                  sx={{
                    fontSize: 30,
                    transform: isExpanded ? "rotate(-90deg)" : "rotate(0deg)",
                    color: "#009fe3",
                  }}
                  onClick={() =>
                    handleToggleMessage(
                      index +
                      findIndexOfItemByUpdatedAt(data?.user_messages, date)
                    )
                  }
                />
              </div>
            </div>
          </div>
        );

        return null;
      }
    );
  };
  function findItemsByUpdatedAt(data, targetUpdatedAt) {
    return data?.filter((item) => {
      if (
        item.updated_at &&
        item.updated_at.seconds === targetUpdatedAt.seconds &&
        item.updated_at.nanoseconds === targetUpdatedAt.nanoseconds
      ) {
        return true;
      }
      return false;
    });
  }
  function findIndexOfItemByUpdatedAt(data, targetUpdatedAt) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (
        item.updated_at &&
        item.updated_at.seconds === targetUpdatedAt.seconds &&
        item.updated_at.nanoseconds === targetUpdatedAt.nanoseconds
      ) {
        return i;
      }
    }
    // If no matching item found, return -1
    return -1;
  }
  // Function to check if an item with the same date already exists in the list
  function isAlreadyAdded(logList, targetDate) {
    return logList.some((item) => {
      return (
        item.date.seconds === targetDate.seconds &&
        item.date.nanoseconds === targetDate.nanoseconds
      );
    });
  }
  function containsHTMLTags(inputString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(inputString, "text/html");
    return {
      ishtml: Array.from(doc.body.childNodes).some(
        (node) => node.nodeType === 1
      ),
      content: doc.body.textContent || "",
    };
  }
  //open the PDF in a new window
  function openPdfInNewWindow(pdfUrl) {
    window.open(pdfUrl, "_blank");
  }
  return (
    <>
      <div className="layoutstepsbar" style={{ height: "70px" }}>
        <div class="row layoutcontent full-height">
          <div className="einzug ">
            {data.ticket_number && (
              <h3>
                <span>Ticket-Nr°: </span>
                <span className=" ml-1">{data.ticket_number}</span>
              </h3>
            )}
          </div>
        </div>
      </div>
      <div className="outer-container">
        <div className="card-style">
          <div className="SonCard">
            <div className="card-shadow">
              <div className="after-cards">
                <div className="textfieldsdateplzObOw">
                  <div
                    className="row mt-2 mb-2"
                    style={{ marginLeft: "0.1rem" }}
                  >
                    <div
                      className="col-md-6 pl-0  mt-3 mb-3"
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <div className="wohnungsdaten" style={{ width: "100%" }}>
                        <div className="first_wohnungsdaten col-md-12 ">
                          <div className="row padding-center">
                            <h3>KUNDENINFORMATIONEN</h3>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          border: "1px solid #009fe3",
                          height: "100%",
                        }}
                      >
                        <div style={{ padding: "10px 30px" }}>
                          <ul>
                            {(userData.first_name || userData.last_name) && (
                              <li className="item-tickett">
                                <span>Vor- und Nachname:</span>
                                <span>
                                  {" "}
                                  {`${userData.gender == "female"
                                      ? "Frau "
                                      : userData.gender == "male"
                                        ? "Herr "
                                        : ""
                                    }${userData.first_name +
                                    " " +
                                    userData.last_name
                                    }`}
                                </span>
                              </li>
                            )}
                            {userData.phone && (
                              <li className="item-tickett">
                                <span>Rufnummer:</span>
                                <span> {userData.phone}</span>
                              </li>
                            )}
                            {userData.company && (
                              <li className="item-tickett">
                                <span>Unternehmen:</span>
                                <span> {userData.company}</span>
                              </li>
                            )}
                            {data.user_email && (
                              <li className="item-tickett">
                                <span>E-Mail:</span>
                                <span> {data.user_email}</span>
                              </li>
                            )}
                            {userData.adress && (
                              <li className="item-tickett">
                                <span>Adresse:</span>
                                <span> {userData.adress}</span>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6 pl-0  mt-3 mb-3"
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <div className="wohnungsdaten" style={{ width: "100%" }}>
                        <div className="first_wohnungsdaten col-md-12 ">
                          <div className="row padding-center">
                            <h3>Ticket-Details</h3>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ width: "100%", border: "1px solid #009fe3" }}
                      >
                        <div style={{ padding: "10px 30px" }}>
                          <ul className=" list-unstyled">
                            {data.subject && (
                              <li className="item-tickett">
                                <span>Betreff: </span>
                                <span>{Translation(data.subject)}</span>
                              </li>
                            )}
                            {data.error_source && (
                              <li className="item-tickett">
                                <span>Error Source: </span>
                                <span>{Translation(data.error_source)}</span>
                              </li>
                            )}
                            {data.created_at && (
                              <li className="item-tickett">
                                <span>Erstell am : </span>
                                <span>
                                  {moment
                                    .unix(data.created_at?._seconds)
                                    .add(data.created_at?._nanoseconds / 1000000)
                                    .format("DD.MM.YYYY")}
                                </span>
                              </li>
                            )}
                            {data.status && (
                              <li className="item-tickett">
                                <span> Status: </span>
                                {getStatusComponent(data.status)}
                              </li>
                            )}
                            {data.updated_at && (
                              <li className="item-tickett">
                                <span>Zuletzt aktualisiert am: </span>
                                <span>
                                  {moment
                                    .unix(data.updated_at?._seconds)
                                    .add(data.updated_at?._nanoseconds / 1000000)
                                    .format("DD.MM.YYYY")}
                                </span>
                              </li>
                            )}
                            {data.app_version && (
                              <li className="item-tickett">
                                <span>Version der Anwendung: </span>
                                <span>{data.app_version}</span>
                              </li>
                            )}
                            {data.app_platform && (
                              <li className="item-tickett">
                                <span>VERLAUF: </span>
                                <span>{data.app_platform}</span>
                              </li>
                            )}
                            {data.attachments?.length !== 0 && (
                              <li className="item-tickett">
                                <span>Anhänge: </span>
                                <span>
                                  <ul>
                                    {data.attachments?.map((item, index) => (
                                      <li
                                        key={index}
                                        onClick={() =>
                                          openPdfInNewWindow(item?.url)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.file_name?.length < 10
                                          ? item.file_name
                                          : item.file_name.substring(0, 10) +
                                          "..."}
                                      </li>
                                    ))}
                                  </ul>
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 mb-3">
                    <div className="col-md-12">
                      <TextField
                        InputLabelProps={{
                          style: {
                            textTransform: "uppercase",
                            padding: "0 5px 0 0",
                            backgroundColor: "white",
                          },
                        }}
                        rows="4"
                        multiline={true}
                        name="message"
                        id="message"
                        value={message}
                        type="text"
                        label="Ihre Nachricht an uns "
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChangeField(e)}
                        required
                        error={error}
                      />
                    </div>
                  </div>
                  <div
                    className="row mt-2 mb-2"
                    style={{ marginLeft: "0.1rem" }}
                  >
                    <div className="col-md-8"></div>

                    <div className="col-md-4 d-flex justify-content-end">
                      <div className="weiter-protocolltype  d-flex justify-content-around w-100">
                        <button
                          type="button"
                          onClick={handleClick}
                        //className="w-half"
                        //style={{ width: "100%" }}
                        >
                          SENDEN
                          <ArrowForwardIosIcon
                            className="icon-buttonweiterPT"
                            sx={{ fontSize: 16 }}
                          />
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TicketReply;
