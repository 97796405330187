/**
 * App Redux Action Types
 */

// Auth Actions
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGING_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const SIGNUP_USER = 'SIGNUP_USER'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE'
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGIN_EMAIL_CHANGED = 'SIGNIN_EMAIL_CHANGED'
export const LOGIN_PASSWORD_CHANGED = 'SIGNIN_PASSWORD_CHANGED'



// App Settings
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR';
export const MINI_SIDEBAR = 'MINI_SIDEBAR';
export const DARK_MODE = 'DARK_MODE';
export const RTL = 'RTL';
export const HORIZONTAL_MENU = 'HORIZONTAL_MENU';
export const CHOOSE_THEME = 'CHOOSE_THEME';
export const NOTIFICATION_SIDEBAR = 'NOTIFICATION_SIDEBAR';


// User Settings
export const EMAIL_ON_SWITCH_CHANGE = 'EMAIL_ON_SWITCH_CHANGE';
export const SET_LANGUAGE = 'SET_LANGUAGE'

// Menu List 
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_THIRD_MENU = 'TOGGLE_THIRD_MENU';
export const TOGGLE_FOURTH_MENU = 'TOGGLE_FOURTH_MENU';
export const ONLOAD_TOGGLE_MENU = 'ONLOAD_TOGGLE_MENU';

//JWT
export const JWT_LOGIN_REQUEST = 'JWT_USERS_LOGIN_REQUEST';
export const JWT_LOGIN_SUCCESS = 'JWT_USERS_LOGIN_SUCCESS';
export const JWT_LOGIN_FAILURE = 'JWT_USERS_LOGIN_FAILURE';