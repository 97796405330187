// StatusContext.js
import React, { createContext, useContext, useState,useEffect } from 'react';

const StatusContext = createContext();

export const StatusProvider = ({ children }) => {
  const [status, setStatus] = useState(null);

  const updateStatus = (newStatus) => {
    setStatus(newStatus);
  };
   // UseEffect to handle initial status based on localStorage
   useEffect(() => {
    const storedStatus = localStorage.getItem('status');
    if (storedStatus) {
      setStatus(storedStatus);
    }
  }, []); 

  return (
    <StatusContext.Provider value={{ status, updateStatus }}>
      {children}
    </StatusContext.Provider>
  );
};

export const useStatus = () => {
  return useContext(StatusContext);
};
