import {
  getDocs,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  doc,
  getDoc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { DateFormatter } from "../helpers/DateFormatter";
import { Timestamp } from 'firebase/firestore';
export const promocodeService = {
  ValidePromoCode,
  createCodePromo,
  updatePromoCode,
  getPromocode,
  deletePromoCode,
  getAllPromoCode,
};
async function ValidePromoCode(code) {
  const currentDate = new Date(); // Initialize currentDate as a JavaScript Date object
  currentDate.setUTCHours(currentDate.getUTCHours() + 2); // Example modification
  const promoCodeRef = collection(db, "xc_promo_code");
  const queryRef = query(promoCodeRef, where("promo_code", "==", code));
  const querySnapshot = await getDocs(queryRef);
  const promoCodes = await formatQuerySnapshotToArray(querySnapshot);

  if (promoCodes.length === 0) {
    return { error: "Promo code not found." };
  }

  // Assuming there's only one promo code with the same code at a time
  const promoCode = promoCodes[0];
  const expirationDate = promoCode.expiration_date.toDate(); // Convert Firestore Timestamp to JavaScript Date

  if (expirationDate <= currentDate) {
    return { error: "Promo code has expired." };
  }

  if (promoCode.limit_users === 0 || promoCode.limit_users<-1) {
    return { error: "Promo code has reached its usage limit." };
  }

  return { msg:"valid",
           data:promoCode
   };
}

async function createCodePromo(object) {
    if (!object || !object.id) {
    throw new Error("Object or object.id is undefined or null");
  }

  const docRef = doc(db, "xc_promo_code", object.id);
  try {
    await setDoc(docRef, {
      ...object,
      limit_users:object.limit_users===""? -1 : parseInt(object.limit_users,10),
      expiration_date: new Date(object.expiration_date),
      creation_date: DateFormatter.initDate(),
    });
    return {
      message: "Promo code created successfully!",
    };
  } catch (error) {
    console.error("Error creating promo code:", error);
    throw error; // Rethrow the error for further handling
  }
}

async function updatePromoCode(userId, id) {
  console.log(userId,id)
  const docRef = doc(db, "xc_promo_code", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const { limit_users, users } = docSnap.data();

    // Decrease limit_users by 1
    const updatedLimitUsers = limit_users=== - 1?limit_users : limit_users - 1;

    // Initialize users array if it's null or undefined
    const updatedUsers = users || []; // Initialize as empty array if null or undefined
    updatedUsers.push(userId); // Add userId to the array

    // Update the document
    await updateDoc(docRef, {
      limit_users: updatedLimitUsers,
      users: updatedUsers,
    });

    console.log("Document updated successfully!");
  } else {
    console.log("Document does not exist.");
  }
}


async function getPromocode(id) {
  let userQuerry = query(
    collection(db, "xc_promo_code"),
    where("id", "==", id)
  );
  const userDoc = await getDocs(userQuerry);
  return userDoc;
}
async function deletePromoCode(id) {
  const docRef = doc(db, "xc_promo_code", id);
  await deleteDoc(docRef);
}
async function getAllPromoCode() {
  let userQuerry = query(collection(db, "xc_promo_code"));
  const userDoc = await getDocs(userQuerry);
  const formattedArray = await formatQuerySnapshotToArray(userDoc);
  return formattedArray;
}

const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};
