/**
 * Courses Routing File
 */

import Store from 'Stored';
import { StatusProvider } from 'context/StatusContext';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from '../ProtectedRoutes.js'
import {
    AsyncImportProductComponent
} from 'routes';

const ImportProducts = ({ match }) => (
	<Switch>
		<Store>
			<StatusProvider>
        <Route path={`${match.url}`} component={AsyncImportProductComponent} route={"product_import"}></Route>		
		</StatusProvider>
		</Store>

	</Switch>
)
export default ImportProducts;