import React, { Component } from 'react';

class ApiDocs extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = "https://cdn.jsdelivr.net/npm/redoc@next/bundles/redoc.standalone.js";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }

  render() {
    const htmlContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>API documentation for managing real estate.</title>
        </head>
        <body>
          <noscript>
            ReDoc requires JavaScript to function. Please enable it to browse the documentation.
          </noscript>
          <redoc spec-url="https://www.x-cite-web.de:5000/uploads/apiRS.json"></redoc>
        </body>
      </html>
    `;

    return (
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
  }
}

export default ApiDocs;