export const ticketService = {
  createTicket,
  getOrder,
  getAllTickets,
  getTicketById,
  updateStatus,
  sendMessage,
};
async function sendMessage(object) {
  const requestOptions = {
    method: "POST",
    body: object,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/ticket/add`,
    requestOptions
  ).then(handleResponse);
}
async function updateStatus(id, object) {
  const requestOptions = {
    method: "PUT",
    body: object,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/ticket/${id}`,
    requestOptions
  ).then(handleResponse);
}
async function getTicketById(id) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/ticket/${id}`,
    requestOptions
  ).then(handleResponse);
}
async function getAllTickets() {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/ticket/all`,
    requestOptions
  ).then(handleResponse);
}
async function createTicket(formData) {
  const requestOptions = {
    method: "POST",
    body: formData,
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/ticket/create`,
    requestOptions
  ).then(handleResponse);
}

async function getOrder(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: object,
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL}/ticket/orders`,
    requestOptions
  ).then(handleResponse);
}
// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.msg) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
