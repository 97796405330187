/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { FirebaseContextProvider } from "../../../context/firebaseContext";
import { Route, Switch } from 'react-router-dom'
import {
	AsyncKautionListComponent,
} from 'routes';

const Dashboard = ({ match }) => (
	<Switch>
		<Store>
			<FirebaseContextProvider>
				<Route path={`${match.url}`} component={AsyncKautionListComponent}></Route>
			</FirebaseContextProvider>
		</Store>

	</Switch>
)
export default Dashboard;