/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { FirebaseContextProvider } from "../../context/firebaseContext";
import { Route, Switch } from 'react-router-dom'
import {
	AsyncContractOverviewComponent,
	AsyncDashboardComponent, AsyncProtocolControlOverviewComponent, AsyncProtocolOverviewComponent,
} from 'routes';

const Dashboard = ({ match }) => (
	<Switch>
		<Store>
			<FirebaseContextProvider>
				<Route path={`${match.url}`} component={AsyncDashboardComponent}></Route>
				<Route path={`${match.url}/protocol-overview`} component={AsyncProtocolOverviewComponent}></Route>
				<Route path={`${match.url}/show_protocolcontrol`} component={AsyncProtocolControlOverviewComponent}></Route>
				<Route path={`${match.url}/update_protocolcontrol`} component={AsyncProtocolControlOverviewComponent}></Route>
				<Route path={`${match.url}/protocolcontrol`} component={AsyncProtocolControlOverviewComponent}></Route>
				<Route path={`${match.url}/contract-overview`} component={AsyncContractOverviewComponent}></Route>
				</FirebaseContextProvider>
		</Store>

	</Switch>
)
export default Dashboard;