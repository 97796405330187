
import { query, getDoc, where, collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";

export const zipCodeService = {
    getZipCode
};


async function getZipCode(zip_code) {
    let plzQuerry = query(
        collection(db, "xc_plz_2"),
        where("postal_code", "==", zip_code)
    );
    const querySnapshot = await getDocs(plzQuerry);
    const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
    return formattedArray;

}

const formatQuerySnapshotToArray = async (querySnapshot) => {
    const dataArray = [];

    querySnapshot.forEach((doc) => {
        const data = doc.data();
        dataArray.push(data);
    });

    return dataArray;
};