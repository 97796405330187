/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
	AsyncImpressumComponent,
	AsyncPolicyComponent,
	AsyncTermOfUseComponent,
	AsyncDslComponent,
} from 'routes';
const Legal = ({ match }) => (
	<Switch>
		<Store>
				<Route path={`${match.url}/impressum`} component={AsyncImpressumComponent}></Route>
				<Route path={`${match.url}/privacy_info`} component={AsyncPolicyComponent}></Route>
				<Route path={`${match.url}/termsofuse`} component={AsyncTermOfUseComponent}></Route>
				<Route path={`${match.url}/dsl`} component={AsyncDslComponent}></Route>
				
			
		</Store>

	</Switch>
)
export default Legal;