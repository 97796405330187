import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Paper,
  IconButton,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { userService } from "../../_services/user.service";
import { autoService } from "../../_services/autocomplete.service";
import NotValidIcon from "../../assets/Icons/notvalid_icon.svg";
import swal from "sweetalert";
import { div } from "react-dom-factories";

export default function FieldsAutocomplete({ fill, ...props }) {
  const user = userService.getId();
  const [optionSelected, setOptionSelected] = useState("");
  const [company_id, setCompanyId] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [type, setType] = useState(props.type);
  const [dropdownOpen, setDropdownOpen] = useState(true);

  const getData = async () => {
    const userDoc = await userService.getUser(user);
    const userObject = userDoc.docs.map((doc) => doc.data());
    const companyId = userObject[0].company_id;
    setCompanyId(companyId);
    let result = await autoService.getAutoList(
      type == "product_installation_position" ? "counter_location" : props.type,
      props.level,
      companyId,
      props.roomID,
      props.deffectNameID
    );
    //let statics = result.filter((doc) => doc.is_static == true);
    //let not_statics = result.filter((doc) => doc.is_static == false);
    //const combinedArray = [...statics, ...not_statics];
    const sortedArray = result.sort((a, b) => {
      if (a.value?.toLowerCase() < b.value?.toLowerCase()) return -1;
      if (a.value?.toLowerCase() > b.value?.toLowerCase()) return 1;
      return 0;
    });
    setData(sortedArray);
    setLoading(false);
    props.setOptions(
      result.filter((option) =>
        option.value?.toLowerCase().includes(inputValue?.toLowerCase())
      )
    );
  };
  const handleChange = (event, value, name) => {
    setDropdownOpen(true);
    if (value) {
      setEditedText(value);
    } else {
      setEditedText(event.target.value);
    }
    if (props.type == "room_generale_description") {
      const paramsNumber = props.handleChange.length;
      if (value) {
        if (paramsNumber == 2) {
          props.handleChange(name, value);
        } else {
          props.handleChange(value, props.index, name);
        }
        fill(value, company_id);
      } else {
        if (paramsNumber == 2) {
          props.handleChange(event.target.name, event.target.value);
        } else {
          props.handleChange(
            event.target.value,
            props.index,
            event.target.name
          );
        }
        fill(event.target.value, company_id);
      }
    }

    if (props.type == "deffect_type") {
      if (value) {
        props.handleChange(name, value);
      } else {
        props.handleChange(event.target.name, event.target.value);
      }
    }
    //
    if (props.type == "room_name") {
      if (props.level == 0) {
        if (value) {
          props.handleChange(value, props.index, name);
          fill(value, company_id);
        } else {
          props.handleChange(
            event.target.value,
            props.index,
            event.target.name
          );
          fill(event.target.value, company_id);
        }
      } else if (props.level == 1) {
        if (value) {
          let e = {
            target: {
              name: "deffect_name",
              value: value,
            },
          };
          props.handleChange(e, props.index);
          fill(value, company_id);
        } else {
          let e = {
            target: {
              name: "deffect_name",
              value: event.target.value,
            },
          };
          props.handleChange(e, props.index);
          fill(event.target.value, company_id);
        }
      } else {
        if (value) {
          let e = {
            target: {
              name: "deffect_type",
              value: value,
            },
          };
          props.handleChange(e, props.index);
          fill(value, company_id);
        } else {
          let e = {
            target: {
              name: "deffect_type",
              value: event.target.value,
            },
          };
          props.handleChange(e, props.index);
          fill(event.target.value, company_id);
        }
      }
    }

    if (
      props.type == "image_description" ||
      props.type == "room_deffect_notice"
    ) {
      if (value) {
        props.handleChange(name, value, props.index);
        fill(value, company_id);
      } else {
        props.handleChange(event.target.name, event.target.value, props.index);
        fill(event.target.value, company_id);
      }
    }
    if (props.type == "controle_comment") {
      if (value) {
        props.handleChange(name, value, props.index, props.secondIndex);
        fill(value, company_id);
      } else {
        props.handleChange(
          event.target.name,
          event.target.value,
          props.index,
          props.secondIndex
        );
        fill(event.target.value, company_id);
      }
    }
    if (props.type == "counter_location") {
      if (props.counter_type == "electricity" || props.counter_type == "gas") {
        if (value) {
          props.handleChange(
            value,
            props.counter_type,
            "counter_location",
            props.index,
            props.idCounter
          );
          fill(value, company_id);
        } else {
          props.handleChange(
            event.target.value,
            props.counter_type,
            "counter_location",
            props.index,
            props.idCounter
          );
          fill(event.target.value, company_id);
        }
      } else {
        if (value) {
          props.handleChange(
            value,
            props.counter_type,
            "counter_location",
            props.index,
            props.idCounter
          );
          fill(value, company_id);
        } else {
          props.handleChange(
            event.target.value,
            props.counter_type,
            "counter_location",
            props.index,
            props.idCounter
          );
          fill(event.target.value, company_id);
        }
      }
    }

    if (props.type == "key_name" || props.type == "key_notice") {
      if (value) {
        props.handleChange(value, props.index, props.type);
        fill(value, company_id);
      } else {
        props.handleChange(event.target.value, props.index, props.type);
        fill(event.target.value, company_id);
      }
    }

    if (props.type == "product_installation_position") {
      let e = {
        target: {
          name: props.type,
          value: value,
        },
      };
      if (value) {
        props.handleChange(e);
        fill(value, company_id);
      } else {
        props.handleChange(event);
        fill(event.target.value, company_id);
      }
    }
    if (props.type == "counter_name") {
      if (value) {
        //handleOk(value);
        fill(value, company_id);
      } else {
        //handleOk(event.target.value);
        fill(event.target.value, company_id);
      }
    }
  };

  const renderImage = () => {
    if (props.type == "room_deffect_notice") {
      return (
        <img
          className="BeschreibungIcon"
          src={NotValidIcon}
          alt="notvalidateSclusselOvwerview Icon"
          style={{
            height: "1.25rem",
            padding: "0rem",
            marginTop: "0rem",
            marginBottom: "0rem",
            filter:
              "invert(50%) sepia(99%) saturate(9999%) hue-rotate(390deg) brightness(95%) contrast(119%)",
          }}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const renderLabel = () => {
    if (
      props.type == "room_deffect_notice" ||
      props.type == "counter_location" ||
      props.type == "key_name" ||
      props.type == "key_notice" ||
      props.type == "product_installation_position" ||
      props.type == "deffect_type"
    ) {
      return props.placeholder;
    } else if (props.type == "counter_name") {
      return "ZÄHLERNAME";
    } else {
      return props.placeholder;
    }
  };
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    /* if (props.type == "counter_location") {
      console.log("164", event?.target?.value);
      if (props.counter_type == "electricity" || props.counter_type == "gas") {
        props.handleChange(
          event?.target?.value,
          props.counter_type,
          "counter_location"
        );
      } else {
        props.handleChange(
          event?.target?.value,
          props?.counter_type,
          "counter_location",
          props.index
        );
      }
    } else {
      props.handleChange(
        event?.target?.name,
        event?.target?.value,
        props.index
      );
    }*/
    //
    // fill(newInputValue, company_id);
  };
  const filteredOptions = data.filter((option) =>
    option.value?.toLowerCase().includes(inputValue?.toLowerCase())
  );
  const truncateOptionText = (text, wordLimit) => {
    if (text.length > wordLimit) {
      return text.substring(0, wordLimit) + " ...";
    }
    return text;
  };
  const handleDelete = async (e, type, optionToDelete) => {
    // Display a confirmation dialog using swal
    e.preventDefault();
    swal({
      title: "Löschen bestätigen?",
      text: `Möchten Sie den Begriff "${optionToDelete}" wirklich löschen?`,
      icon: "warning",
      buttons: ["Abbrechen", "Löschen"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        // If the user confirms, proceed with the deletion
        //autoService.deleteOption(optionToDelete, company_id, props.type);
        await autoService.deleteAutoOption(
          optionToDelete,
          company_id,
          type,
          props.level,
          props.level == 1
            ? props.roomID
            : props.level == 2
            ? props.deffectNameID
            : null
        );
        setData(data.filter((option) => option.value !== optionToDelete));
        swal("Erfolgreich gelöscht", "", "success");
      } else {
        // If the user cancels, do nothing
      }
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const [isEditing, setIsEditing] = useState(false);
  const [editedOption, setEditedOption] = useState("");
  const [editedText, setEditedText] = useState("");
  const [editedOptionType, setEditedOptionType] = useState("");

  const handleEdit = (index, option) => {
    setIsEditing(true);
    setEditedOptionType(index);
    setEditedOption(option);
    setEditedText(option);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedText("");
  };

  const handleSave = () => {
    const element = data.find((option) => {
      return option.value == editedOption;
    });
    // Exit editing mode
    props.update(
      editedOptionType,
      editedText,
      company_id,
      editedOption,
      element.sub_parent_ids,
      element.parent_ids
    );
    getData();
    setIsEditing(false);
  };
  return (
    <Autocomplete
      disablePortal={true}
      disableClearable
      freeSolo
      id={props.id}
      value={props.value}
      fullWidth
      options={filteredOptions.map((option) => option.value)}
      sx={{
        marginTop: props.style,
      }}
      onChange={(e, value, name) => handleChange(e, value, props.id)}
      onInputChange={handleInputChange}
      open={dropdownOpen}
      PopperProps={{
        disableRestoreFocus: false, // Allow the dropdown to close when clicking outside
      }}
      onClose={() => setDropdownOpen(false)}
      PaperComponent={({ children }) => (
        <Paper
          style={{
            position: "absolute",
            zIndex: 1,
            marginTop: "5px",
            backgroundColor: "#00457C",
            padding: "10px",
            //overflow: "auto",
            //maxHeight: type == "room_name" ? "200px" : "1000px",
            left: 0,
            right: 0,
          }}
        >
          {children}
        </Paper>
      )}
      renderOption={(props, option, { selected }) => {
        const isLoading = option.loading;
        const isLastOption =
          filteredOptions.findIndex((opt) => opt.value === option) ===
          filteredOptions.length - 1;
        return (
          <>
            <li
              {...props}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "5px",
                marginTop: "5px",
                marginBottom: "5px",
                padding: "15px 10px",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                color: "white", // Text color of each item
              }}
            >
              {isLoading ? (
                <div className="autocomplete-action">
                  <CircularProgress size={24} />
                </div>
              ) : (
                <>
                  <span>{truncateOptionText(option, 50)}</span>
                  <div
                    className="autocomplete-action"
                    hidden={
                      filteredOptions.find((opt) => opt.value == option)
                        .is_static == true
                    }
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => handleEdit(type, option)}
                    >
                      <EditIcon fontSize="small" style={{ color: "#fff" }} />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(e, type, option);
                      }}
                    >
                      <DeleteIcon fontSize="small" style={{ color: "#fff" }} />
                    </IconButton>
                  </div>
                </>
              )}
            </li>
            {!isLastOption && <div className="dashed-line"></div>}
          </>
        );
      }}
      renderInput={(params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {renderImage()}
          {isEditing ? (
            <TextField
              {...params}
              shrink={true}
              InputLabelProps={{
                style: {
                  textTransform: "none",
                  padding: "0 5px 0 0",
                  backgroundColor: "#FFFFFF",
                },
                type: "search",
              }}
              name={props.id}
              id={props.id}
              //placeholder={props.placeholder}
              type="text"
              variant="outlined"
              rows="4"
              label={renderLabel()}
              multiline={props.multiline}
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              InputProps={{
                style: { paddingLeft: "10px", textTransform: "none" },
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      style={{ display: "flex" }}
                      className="icon-autocomplete_popup"
                    >
                      <IconButton
                        size="small"
                        onClick={handleSave}
                        style={{ marginRight: "15px" }}
                      >
                        <CheckIcon fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={handleCancel}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <TextField
              {...params}
              shrink={true}
              InputLabelProps={{
                style: {
                  textTransform: "none",
                  padding: "0 5px 0 0",
                  backgroundColor: "#FFFFFF",
                },
                type: "search",
              }}
              name={props.id}
              id={props.id}
              //placeholder={props.placeholder}
              onClick={() => setDropdownOpen(true)}
              type="text"
              variant="outlined"
              rows="4"
              autoFocus={true} // Add this line to focus the TextField automatically
              label={renderLabel()}
              multiline={props.multiline}
              value={props.value}
              onChange={(e, value, name) => handleChange(e, value, name)}
            />
          )}
          {loading && <CircularProgress size={24} />}
        </div>
      )}
    />
  );
}
