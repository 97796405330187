import React, { useCallback, useEffect } from "react";
import { useState, createContext } from "react";
export const Context = createContext();

const initialState = {
  //lieferAdresse: {
  alternative_billing: false,
  card: -1,
  alternative_billing_adressNote: null,
  alternative_billing_location: null,
  alternative_billing_postal_code: null,
  alternative_billing_house_number: null,
  alternative_billing_street: null,
  alternative_billing_company_name: null,
  alternative_billing_last_name: null,
  alternative_billing_first_name: null,
  object_position: null,
  alternative_billing_title: null,
  alternative_billing_gender: null,
  address_note: null,
  customer_location: null,
  postal_code: null,
  nextSteps: false,
  customer_house_number: null,
  contractStatus: "active",
  contract_is_expired: false,
  contract_legal: false,
  contract_leagl: false,
  created_at: null,
  created_by: null,
  created_remote: true,
  groupe_is_updated: false,
  idObject: null,
  idRealState: null,
  local_image_name: null,
  local_image_path: null,
  local_image_thumb: null,
  local_signature_name: null,
  local_signature_path: null,
  productIsExpired: false,
  pdf_created: false,
  pdf_url: null,
  product_duration: null,
  product_installation_position: null,
  customer_street: null,
  company_name: null,
  customer_mobile_phone: null,
  customer_email: null,
  customer_landline_phone: null,
  customer_birth_date: "",
  product_market_Location_number: null,
  customer_first_name: null,
  customer_last_name: null,
  customer_title: null,
  customer_gender: null,
  hasManyObject: false,
  sepa_legal: null,
  sepa_check_box: false,
  sepa_iban: null,
  sepa_bic: null,
  sepa_account_holder: null,
  sepa_bank: null,
  product_counter_number: null,
  product_counter_reading: null,

  product_counter_image_url: null,
  product_counter_reading2: null,
  product_counter_type: "singleCounter",
  product_move_date: null,
  product_move_date_supplier: null,
  product_the_earliest_possible_date: false,
  MoveDate: "",
  supplierDate: "",
  product_last_year_consumption: null,
  previous_customer_number: null,
  persons_number: 1,
  product_previous_customer_number: null,
  product_previous_supplier: null,
  product_new_move: false,
  product_name: "",
  product_renewal: null,
  customer_post_code: null,
  product_sealed: false,
  product_total_cost: null,
  product_id: "",
  product_updated_at: "",
  product_type: "",
  company_id: null,
  product_change_supplier: false,
  realEstateCreated: false,
  realEstateObjectNumber: 0,
  lastYearConsumption: "",
  send_email: null,
  send_pdf: false,
  sepa_signature_local_path: null,
  sepa_signature_name: null,
  sepa_signature_thumb: null,
  sepa_signature_time_stamp: null,
  sepa_signature_url: null,
  signature_thumb: null,
  signature_time_stamp: null,
  signature_url: null,
  synched_remote: true,
  search: false,
  product_base_price: null,
  product_consumtion_price: null,
  product_counter_reading_2: null,
  updated_at: "",
  updated_by: null,
  products: [],
  id: null,
  beginValidation: false,
  requiredFields: [
    "customer_birth_date",
    "customer_email",
    "customer_landline_phone",
    "customer_mobile_phone",
    "customer_first_name",
    "customer_gender",
    "customer_last_name",
    "customer_location",
    "customer_street",
    "customer_house_number",
    "alternative_billing_first_name",
    "alternative_billing_last_name",
    "alternative_billing_postal_code",
    "alternative_billing_street",
    "alternative_billing_location",
    "alternative_billing_house_number",
    "sepa_iban",

    "product_counter_number",
    "alternative_billing_gender",
    "product_new_move",
    "product_change_supplier",
  ],
  requiredBankInfo: ["sepa_iban"],

  requiredCounterInfo: ["product_counter_number"],
  validationErrors: {
    customer_first_name: false,
    alternative_billing_gender: false,
    customer_landline_phone: false,
    customer_mobile_phone: false,
    customer_email: false,
    customer_gender: false,
    customer_last_name: false,
    customer_location: false,
    customer_street: false,
    customer_house_number: false,
    alternative_billing_first_name: false,
    alternative_billing_last_name: false,
    alternative_billing_postal_code: false,
    alternative_billing_street: false,
    alternative_billing_location: false,
    alternative_billing_house_number: false,
    sepa_iban: false,
    product_counter_number: false,

    product_new_move: false,
    product_change_supplier: false,
  },
  // product_new_move:false,product_change_supplier:false
  // "product_new_move","product_change_supplier"
};

const Store = ({ children }) => {
  const [globalState, setGlobalState] = useState(() => {
    const storedGlobalState = localStorage.getItem("globalState");
    return storedGlobalState ? JSON.parse(storedGlobalState) : initialState;
  });

  const updateGlobalState = useCallback(
    (data) => {
      setGlobalState((prevState) => ({
        ...prevState,
        ...data,
      }));
    },
    [setGlobalState]
  );

  useEffect(() => {
    localStorage.setItem("globalState", JSON.stringify(globalState));
  }, [globalState]);

  return (
    <Context.Provider value={[globalState, updateGlobalState]}>
      {children}
    </Context.Provider>
  );
};
export default Store;
