/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
	AsyncUserComponent,
} from 'routes';

const User = ({ match }) => (
	<Switch>
		<Store>
			 <Route path={`${match.url}/:userID`} component={AsyncUserComponent}></Route>
			
		</Store>

	</Switch>
)
export default User;