/**
 * App Language Provider
 * Add more locales here
 */
import React, {addLocaleData } from 'react-intl';

import deLang from './entries/de-DE';

const AppLocale = {
   de: deLang,
   
};

addLocaleData(AppLocale.de.data);

export default AppLocale;