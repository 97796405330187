/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
    AsyncAddMaklerComponent,
    AsyncListMaklersComponent,
	AsyncTicketsComponent,
	AsyncTicketDetailComponent
} from 'routes';
import ProtectedRoute from '../ProtectedRoutes.js'

const Administration = ({ match }) => (
	<Switch>
		<Store>
        <Route path={`${match.url}/subMaklers`} component={AsyncListMaklersComponent} route={"mitarbeiter"} ></Route>
        <Route path={`${match.url}/groups`} component={AsyncListMaklersComponent} route={"groups"}></Route>
		<Route path={`${match.url}/create_mitarbeiter`} component={AsyncAddMaklerComponent} route={"mitarbeiter"}></Route>
		<Route path={`${match.url}/tickets`} component={AsyncTicketsComponent} route={"tickets"} ></Route>
		<Route path={`${match.url}/ticket/:id`} component={AsyncTicketDetailComponent} route={"ticket_id"}></Route>
			
		</Store>

	</Switch>
)
export default Administration;