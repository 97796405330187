import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import warningIcon from "../../assets/Icons/warningIcon.png";

function swalAlert(onCancel, handleThirdButton, handleConfirm) {
  return (
    <>
      <SweetAlert
        custom
        title="ACHTUNG"
        customIcon={warningIcon}
        onConfirm={handleConfirm}
        onCancel={onCancel}
        onClose={onCancel}
        onThirdButton={handleThirdButton}
        closeOnClickOutside={false}
        customButtons={
          <React.Fragment>
            <div className="row w-full">
              <div className="col-md-4 padding-side popup-control">
                <button
                  onClick={onCancel}
                  className=" button-cancel w-full custom-icon d-flex align-items-center justify-content-center h-full"
                >
                  Ohne speichern verlassen
                </button>
              </div>
              <div className="col-md-4 padding-side popup-control">
                <button
                  onClick={handleThirdButton}
                  className="button-cancelandsave w-full custom-icon d-flex align-items-stretch"
                  style={{ lineHeight: "2.6" }}
                >
                  Löschen und Schließen
                </button>
              </div>
              <div className="col-md-4 padding-side popup-control">
                <button
                  onClick={handleConfirm}
                  className="button-save w-full custom-icon d-flex align-items-stretch"
                >
                  Speichern und Schließen
                </button>
              </div>
            </div>
          </React.Fragment>
        }
      >
        Sie sind im Begriff das aktuelle Formular zu verlassen. Wie möchten Sie
        vorgehen?
      </SweetAlert>
    </>
  );
}

export default swalAlert;
